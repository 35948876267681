import { useMemo } from "react";

import { useAppSelector } from "stores";
import { selectIsCartLoaded } from "stores/features/cart";
import { selectForceShowCart } from "stores/features/ui";
import { SECTION } from "utils/constants";
import { useAppSection, useMobile } from "utils/hooks";

type RightPanelType = "cart" | "preview";

const cartSections = [
  SECTION.license,
  SECTION.services,
  SECTION.annualPlan,
  SECTION.nativePlugins,
];

export const topBarCartSections = [
  ...cartSections,
  SECTION.pushNotifications,
  SECTION.build,
];

export const useRightPanelType = (): RightPanelType => {
  const [appSection] = useAppSection();
  const isCartLoaded = useAppSelector(selectIsCartLoaded);
  const forceShowCart = useAppSelector(selectForceShowCart);
  const isMobile = useMobile();

  const type: RightPanelType = useMemo(() => {
    if (!isCartLoaded) {
      return "preview";
    }
    if (forceShowCart) {
      return "cart";
    }
    if (!cartSections.includes(appSection) || isMobile) {
      return "preview";
    }
    return "cart";
  }, [appSection, forceShowCart, isCartLoaded, isMobile]);

  return type;
};
