import React, { SVGProps } from "react";

type IconInfoProps = SVGProps<SVGSVGElement> & {
  iconColor?: string;
};

const IconInfo: React.FC<IconInfoProps> = ({
  iconColor = "black",
  ...props
}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 3.55555C6.36819 3.55555 6.66667 3.25708 6.66667 2.88889C6.66667 2.5207 6.36819 2.22222 6 2.22222C5.63181 2.22222 5.33333 2.5207 5.33333 2.88889C5.33333 3.25708 5.63181 3.55555 6 3.55555Z"
      fill={iconColor}
    />
    <path
      d="M7.5 8.35556H6.5V4.44444H5C4.86739 4.44444 4.74022 4.49595 4.64645 4.58764C4.55268 4.67932 4.5 4.80367 4.5 4.93333C4.5 5.06299 4.55268 5.18735 4.64645 5.27903C4.74022 5.37072 4.86739 5.42222 5 5.42222H5.5V8.35556H4.5C4.36739 8.35556 4.24022 8.40706 4.14645 8.49875C4.05268 8.59043 4 8.71478 4 8.84444C4 8.97411 4.05268 9.09846 4.14645 9.19014C4.24022 9.28183 4.36739 9.33333 4.5 9.33333H7.5C7.63261 9.33333 7.75979 9.28183 7.85356 9.19014C7.94732 9.09846 8 8.97411 8 8.84444C8 8.71478 7.94732 8.59043 7.85356 8.49875C7.75979 8.40706 7.63261 8.35556 7.5 8.35556Z"
      fill={iconColor}
    />
    <path
      d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259972 4.80026 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM6 11C5.0111 11 4.0444 10.7068 3.22215 10.1573C2.39991 9.60794 1.75904 8.82705 1.3806 7.91342C1.00217 6.99979 0.90315 5.99445 1.09608 5.02455C1.289 4.05464 1.76521 3.16373 2.46447 2.46447C3.16373 1.7652 4.05465 1.289 5.02455 1.09607C5.99445 0.903147 6.99979 1.00216 7.91342 1.3806C8.82705 1.75904 9.60794 2.3999 10.1573 3.22215C10.7068 4.04439 11 5.01109 11 6C11 6.65661 10.8707 7.30679 10.6194 7.91342C10.3681 8.52004 9.99983 9.07124 9.53553 9.53553C9.07124 9.99983 8.52005 10.3681 7.91342 10.6194C7.30679 10.8707 6.65661 11 6 11Z"
      fill={iconColor}
    />
  </svg>
);

export default IconInfo;
