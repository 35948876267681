import classNames from "classnames";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconBookmark from "assets/images/icons/IconBookmark";
import Modal, { ModalProps } from "components/common/Modal";

import ManageBackups from "./ManageBackups";
import styles from "./styles.module.scss";

const ModalManageBackups: React.FC<ModalProps> = ({
  onClose,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={classNames(styles.modal)}
      onClose={onClose}
      title={t("sections.overview.manageBackupsTitle")}
      subtitle={
        <Trans
          components={{
            icon: (
              <span className={styles.subtitleIcon}>
                <IconBookmark />
              </span>
            ),
          }}
          i18nKey={t("sections.overview.manageBackupsSubTitle")}
        />
      }
      {...otherProps}
    >
      <ManageBackups />
    </Modal>
  );
};

export default ModalManageBackups;
