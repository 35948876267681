import { appBuilderUpdated } from "stores/features/appBuilder/utils";
import { backupsUpdated } from "stores/features/backups";
import { AppBuilder, AppConfig } from "utils/appBuilder";

import apiSlice from ".";
import { TagType } from "./constants";

export interface BackupItem {
  _id: string;
  privateKey: string;
  name: string;
  modifiedBy?: string;
  appConfig: AppConfig;
  builderState: {
    state: AppBuilder;
  };
  version: number;
  isManuallySaved: boolean;
  created: string;
  yearWeekCode: number;
  isLastofWeek: boolean;
  isModifiedByDeletedUser?: boolean;
  isModifiedBySuperUser?: boolean;
  __v: number;
  status?: "error";
}

export interface ApiGetBackupArgs {
  privateKey: string;
  versionNumber: number | string;
}

export interface APIRenameBackupArgs {
  privateKey: string;
  versionNumber: number;
  name: string;
}

export interface APISaveBackup extends BackupItem {
  result: string;
}

export const emptyBackup: BackupItem[] = [];

const backupsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    saveBackup: build.mutation<APISaveBackup, ApiGetBackupArgs>({
      invalidatesTags: [TagType.Backup],
      query: ({ privateKey, versionNumber }) => ({
        url: `project/${privateKey}/backup/save`,
        method: "POST",
        body: {
          version: versionNumber,
        },
      }),
    }),

    unsaveBackup: build.mutation<APISaveBackup, ApiGetBackupArgs>({
      invalidatesTags: [TagType.Backup],
      query: ({ privateKey, versionNumber }) => ({
        url: `project/${privateKey}/backup/unsave`,
        method: "POST",
        body: {
          version: versionNumber,
        },
      }),
    }),

    renameBackup: build.mutation<APISaveBackup, APIRenameBackupArgs>({
      invalidatesTags: [TagType.Backup],
      query: ({ privateKey, versionNumber, name }) => ({
        url: `project/${privateKey}/backup/rename`,
        method: "POST",
        body: {
          version: versionNumber,
          backupName: name,
        },
      }),
    }),

    restoreBackup: build.mutation<APISaveBackup, ApiGetBackupArgs>({
      invalidatesTags: [TagType.Backup],
      query: ({ privateKey, versionNumber }) => ({
        url: `project/${privateKey}/backup/restore`,
        method: "POST",
        body: {
          version: versionNumber,
        },
      }),
    }),

    previewBackup: build.query<BackupItem, ApiGetBackupArgs>({
      providesTags: [TagType.Backup],
      query: ({ privateKey, versionNumber }) => ({
        url: `project/${privateKey}/backup/${versionNumber}`,
      }),
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(appBuilderUpdated(data.builderState.state));
          dispatch(backupsUpdated({ backupItem: data }));
        } catch (e) {
          // NO-OP
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSaveBackupMutation,
  useUnsaveBackupMutation,
  useRenameBackupMutation,
  useRestoreBackupMutation,
  usePreviewBackupQuery,
} = backupsApi;

export default backupsApi;
