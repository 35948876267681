import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useState } from "react";

import Button from "components/common/Button";
import Modal, { ModalProps } from "components/common/Modal";

import styles from "./styles.module.scss";

interface Props extends ModalProps {
  config?: string;
}

const ModalOutdatedConfig: React.FC<Props> = ({
  onClose,
  onClick,
  onRequestClose,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useCallback(() => {
    if (isLoading || !onClick) return;

    setIsLoading(true);

    onClick();
  }, [isLoading, onClick]);

  return (
    <Modal
      className={styles.modal}
      footer={
        <>
          <Button
            className={styles.modalFooterButton}
            disabled={isLoading}
            onClick={onClose}
            size="lg"
            type="light"
          >
            {t("common.cancel")}
          </Button>

          <Button
            className={styles.modalFooterButton}
            disabled={isLoading}
            loading={isLoading}
            loaderColor="#ffffff"
            onClick={handleConfirm}
            size="lg"
            type="primary"
          >
            {t("common.confirm")}
          </Button>
        </>
      }
      footerClassName={styles.modalFooter}
      headerClassName={styles.modalHeader}
      headerTitleClassName={styles.headerTitle}
      onClose={isLoading ? undefined : onClose}
      onRequestClose={isLoading ? undefined : onRequestClose}
      title={t("sections.build.overwriteChanges")}
      {...otherProps}
    >
      <div className={styles.modalContent}>
        <p className={styles.description}>
          <Trans
            components={{ br: <br /> }}
            i18nKey={t("sections.build.overwriteChangesDescription")}
          />
        </p>
      </div>
    </Modal>
  );
};

export default ModalOutdatedConfig;
