import React, { SVGProps } from "react";

const IconRestoreFlipped: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.11111 7C6.11111 6.14444 6.81111 5.44444 7.66667 5.44444C8.52222 5.44444 9.22222 6.14444 9.22222 7C9.22222 7.85556 8.52222 8.55556 7.66667 8.55556C6.81111 8.55556 6.11111 7.85556 6.11111 7ZM7.66667 0C9.52318 0 11.3037 0.737498 12.6164 2.05025C13.9292 3.36301 14.6667 5.14348 14.6667 7H17L13.8889 10.1111L10.7778 7H13.1111C13.1111 3.99 10.6767 1.55556 7.66667 1.55556C4.65667 1.55556 2.22222 3.99 2.22222 7C2.22117 8.00006 2.49577 8.98106 3.01588 9.83523C3.53598 10.6894 4.28145 11.3837 5.1704 11.8418C6.05935 12.3 7.05737 12.5042 8.05483 12.4322C9.05229 12.3601 10.0106 12.0145 10.8244 11.4333L11.9289 12.5533C11.0464 13.2305 10.0152 13.6877 8.92078 13.8869C7.82636 14.0861 6.70023 14.0215 5.63572 13.6987C4.57121 13.3758 3.59897 12.8039 2.79957 12.0304C2.00018 11.2568 1.39666 10.3039 1.03902 9.25052C0.681379 8.19718 0.579926 7.07378 0.743065 5.97341C0.906202 4.87304 1.32924 3.8274 1.97711 2.92314C2.62498 2.01887 3.47903 1.28203 4.46847 0.773684C5.45792 0.265334 6.55427 0.00011279 7.66667 0Z"
      fill="currentColor"
    />
  </svg>
);

export default IconRestoreFlipped;
