import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { usePopper } from "react-popper";

import { useMobile, usePhone } from "utils/hooks";

import IconInfo from "./IconInfo";
import styles from "./styles.module.scss";

interface Props {
  className?: string;
  iconColor?: string;
  reactComponent?: boolean;
  toolTip?: string;
  toolTipMessage?: string;
}

const InfoPopup: React.FC<Props> = ({
  children,
  className,
  iconColor,
  reactComponent,
  toolTip,
  toolTipMessage,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [triggerElement, setTriggerElement] = useState<HTMLDivElement | null>(
    null
  );

  const [clickedInside, setClickedInside] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  const isPhone = usePhone();
  const isMobile = useMobile();

  const handleMouseEnter = () => {
    if (!isTouchScreen) {
      setShowPopup(true);
    }
  };

  const handleMouseLeave = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isTouchScreen) {
        setShowPopup(false);
      }
    },
    [isTouchScreen]
  );

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (isMobile || isPhone) {
        setShowPopup((prevState) => !prevState);
      }
    },
    [isMobile, isPhone]
  );

  const handleTouchStart = useCallback((e) => {
    e.stopPropagation();
    // e.preventDefault();
    setIsTouchScreen(true);
  }, []);

  const handleTouchEnd = useCallback(
    (e) => {
      e.stopPropagation();
      setIsTouchScreen(false);
      if (!showPopup && !clickedInside) {
        setShowPopup(false);
      }
      setClickedInside(false);
    },
    [clickedInside, showPopup]
  );

  const handlePopupClick = useCallback((e) => {
    e.stopPropagation();
    setClickedInside(true);
  }, []);

  const popperModifiers = useMemo(
    () => [
      {
        name: "arrow",
        options: {
          element: arrowRef,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
    [arrowRef]
  );

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    triggerElement,
    popperElement,
    { modifiers: popperModifiers, placement: "top" }
  );

  return (
    <span className={classNames(styles.infoPopupContainer, className)}>
      <div
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
        className={classNames(
          styles.infoPopupButton,
          toolTip && styles.tooltip
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={setTriggerElement}
      >
        {toolTip || <IconInfo iconColor={iconColor} />}
      </div>
      <div
        className={classNames(
          styles.infoPopup,
          showPopup && styles.visible,
          className
        )}
        ref={setPopperElement}
        style={popperStyles.popper}
        {...popperAttributes.popper}
      >
        <div
          className={classNames(
            styles.infoPopupContent,
            toolTip && styles.toolTip
          )}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
          onClick={handlePopupClick}
          onTouchMove={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {toolTipMessage || children}
        </div>

        <div
          className={classNames(
            styles.infoPopupArrow,
            reactComponent && (isPhone || !isMobile) && styles.reactComponent
          )}
          ref={setArrowRef}
          style={popperStyles.arrow}
          {...popperAttributes.arrow}
        />
      </div>
    </span>
  );
};

export default InfoPopup;
