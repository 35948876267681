import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import Button from "components/common/Button";
import { usePhone } from "utils/hooks";

import styles from "./styles.module.scss";

interface Props {
  hasChange?: boolean;
  className?: string;
  onClick?: () => void;
  onSave?: () => void;
  isSaving?: boolean;
  isDiscarding?: boolean;
}

const ToastMessage: React.FC<Props> = ({
  hasChange,
  className,
  onClick,
  onSave,
  isSaving,
  isDiscarding,
}) => {
  const { t } = useTranslation();
  const isPhone = usePhone();

  return (
    <div className={classNames(styles.toastContentContainer, className)}>
      <div className={classNames(styles.message)}>
        {t("containers.topBar.hasChanges")}
        {hasChange && (
          <div className={styles.note}>
            {t("containers.toast.keepOrDiscard")}
          </div>
        )}
      </div>
      {hasChange ? (
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.toastButton}
            type="outlined"
            size={isPhone ? "sm" : "lg"}
            onClick={onSave}
            disabled={isSaving || isDiscarding}
            loading={isSaving}
          >
            {t("containers.toast.saveMine")}
          </Button>
          <Button
            className={styles.toastButton}
            type="primary"
            size={isPhone ? "sm" : "lg"}
            onClick={onClick}
            loading={isDiscarding}
            disabled={isSaving || isDiscarding}
          >
            {t("containers.toast.discardMine")}
          </Button>
        </div>
      ) : (
        <div className={classNames(styles.buttonsContainer, styles.single)}>
          <Button
            className={styles.toastButton}
            type="primary"
            size={isPhone ? "sm" : "lg"}
            onClick={onClick}
            disabled={isSaving || isDiscarding}
          >
            {t("containers.toast.reloadPage")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ToastMessage;
