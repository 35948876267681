import { decamelize } from "humps";
import set from "lodash/set";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import { ValidationError } from "yup";

import { useAppDispatch, useAppSelector } from "stores";
import {
  appBuilderAuthSchema,
  appBuilderSchema,
} from "stores/features/appBuilder";
import { selectAppBuilder } from "stores/features/appBuilder/utils";
import { metaUpdated } from "stores/features/meta";
import { selectPrivateKey } from "stores/features/project";
import { selectUserCurrentOrganizationId } from "stores/features/user";
import { SECTION } from "utils/constants";
import urls from "utils/urls";

interface ValidateAppBuilderArguments {
  disableScroll?: boolean;
}

const useAppBuilderValidator = () => {
  const dispatch = useAppDispatch();
  const appBuilder = useAppSelector(selectAppBuilder);
  const privateKey = useAppSelector(selectPrivateKey);
  const currentOrganizationId = useAppSelector(selectUserCurrentOrganizationId);
  const router = useRouter();
  const { t } = useTranslation();

  const validateAppBuilder = useCallback(
    async ({ disableScroll }: ValidateAppBuilderArguments = {}) => {
      try {
        if (currentOrganizationId) {
          await appBuilderAuthSchema.validate(appBuilder, {
            abortEarly: false,
          });
        } else {
          await appBuilderSchema.validate(appBuilder, { abortEarly: false });
        }

        dispatch(metaUpdated({ appBuilderErrors: {} }));

        return true;
      } catch (e) {
        if (e instanceof ValidationError) {
          const firstError = e.inner[0];
          const firstErrorSection = (firstError.path || "").split(".")[0];

          if (
            !disableScroll &&
            firstErrorSection &&
            Object.values(SECTION).includes(firstErrorSection as SECTION)
          ) {
            router.push(
              urls.appBuilder(
                privateKey,
                firstErrorSection as SECTION,
                "",
                decamelize((firstError.path || "").split(".")[1] || "", {
                  separator: "-",
                })
              ),
              undefined,
              { shallow: true }
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errors: any = {};
          e.inner.forEach((error) => {
            if (!error.path) return;
            set(
              errors,
              error.path,
              t(
                `fields.validation.${error.path}.${error.type}`,
                {},
                { fallback: "" }
              ) ||
                t(
                  `fields.validation.${error.type}`,
                  {},
                  { fallback: error.errors[0] }
                )
            );
          });
          dispatch(metaUpdated({ appBuilderErrors: errors }));

          return false;
        } else {
          throw e;
        }
      }
    },
    [appBuilder, currentOrganizationId, dispatch, privateKey, router, t]
  );

  return {
    validateAppBuilder,
  };
};

export default useAppBuilderValidator;
