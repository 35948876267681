import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  centerFill?: string;
  containerFill?: string;
};

const IconAdd: React.FC<Props> = ({ ...props }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M-0.656854 5.00012H10.6569M5 -0.656738V10.657"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default IconAdd;
