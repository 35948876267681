import React, { SVGProps } from "react";

const IconBookmark: React.FC<SVGProps<SVGSVGElement>> = (className, props) => (
  <svg
    className={className}
    width="19"
    height="20"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4C1 2.34315 2.34315 1 4 1H14.8C16.4569 1 17.8 2.34315 17.8 4V22L9.4 14.44L1 22V4Z"
      stroke="currentColor"
      strokeWidth="1.45"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconBookmark;
