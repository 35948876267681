import classNames from "classnames";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback } from "react";

import Button from "components/common/Button";
import Modal from "components/common/Modal";
import { useCheckout } from "components/containers/RightPanel/Cart/utils";
import { useListPluginsQuery } from "services/api/plugins";
import { useAppDispatch, useAppSelector } from "stores";
import { cartUpdated, selectCartPrice } from "stores/features/cart";
import { uiUpdated } from "stores/features/ui";
import { formatPrice } from "utils/formatting";
import { useMobile } from "utils/hooks";

import CostSummary from "../CostSummary";
import IconBack from "../icons/back.svg";
import IconCart from "../icons/cartLarge.svg";
import { usePricingPackage } from "../utils";
import styles from "./styles.module.scss";

type Props = {
  className?: string;
  mode?: "appBuilder" | "cart" | "pricing";
};

const PurchaseSummaryBar: React.FC<Props> = ({
  className,
  mode = "pricing",
}) => {
  const { isCheckingOut, onCheckout } = useCheckout({ mode });
  const { data: nativePluginsList = [] } = useListPluginsQuery();
  const { lang, t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [pricingPackage, setPricingPackage] = usePricingPackage();

  const isMobile = useMobile();

  const isModalOpen = router.query?.open === "true";

  const cartPrice = useAppSelector((state) =>
    selectCartPrice(state, nativePluginsList)
  );

  const onCancel = useCallback(() => {
    dispatch(
      cartUpdated({
        cart: {
          basicLicense: false,
          assistedFullServiceConfig: false,
          professionalPublishInitial: false,
          professionalPublishUpdate: false,
          professionalSupport: false,
          appConfigFullService: false,
          samUpdates: false,
          pushNotifications: [],
          nativePlugins: [],
        },
      })
    );
    if (isMobile && mode !== "appBuilder") {
      const element = document.getElementById("__pricing-header");
      if (element) {
        element.scrollIntoView(true);
      }
      setPricingPackage(undefined);
    }
  }, [dispatch, isMobile, mode, setPricingPackage]);

  const handleSummaryClick = useCallback(() => {
    if (mode === "appBuilder") {
      dispatch(
        uiUpdated({ isRightPanelCollapsed: false, forceShowCart: true })
      );
    } else {
      setPricingPackage(pricingPackage, { open: "true" });
    }
  }, [dispatch, mode, pricingPackage, setPricingPackage]);

  const onCloseModal = useCallback(() => {
    router.back();
  }, [router]);

  return (
    <>
      <div
        className={classNames(
          styles.pricingSummaryFooter,
          mode === "appBuilder" && styles.appBuilder,
          className
        )}
      >
        <div className={styles.pricingSummaryFooterPrice}>
          <div className={styles.total}>
            {t("containers.pricing.costSummary.total")}
          </div>

          {formatPrice(lang, cartPrice)}
        </div>

        <div className={styles.pricingSummaryFooterItems}>
          <Button
            className={classNames(
              styles.pricingSummaryFooterButton,
              styles.icon
            )}
            onClick={onCancel}
            type="light"
          >
            <div>
              <IconCart />
            </div>
          </Button>

          <Button
            className={styles.pricingSummaryFooterButton}
            type="light"
            onClick={handleSummaryClick}
          >
            {t("containers.pricing.costSummary.summary")}
          </Button>

          <Button
            className={classNames(
              styles.pricingSummaryFooterButton,
              styles.checkout
            )}
            type="secondary"
            loaderColor="#000000"
            loading={isCheckingOut}
            disabled={isCheckingOut}
            onClick={onCheckout}
          >
            {t("containers.pricing.costSummary.checkoutShort")}
          </Button>
        </div>
      </div>

      <Modal
        className={styles.container}
        headerClassName={styles.none}
        isOpen={isModalOpen}
      >
        <header className={styles.header}>
          <Button className={styles.back} onClick={onCloseModal}>
            <div className={styles.backIcon}>
              <IconBack />
            </div>
            {t("containers.pricing.costSummary.backToSelection")}
          </Button>
        </header>

        <div className={styles.content}>
          <CostSummary className={styles.costSummary} type={mode} />
        </div>
      </Modal>
    </>
  );
};

export default PurchaseSummaryBar;
