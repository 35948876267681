import classNames from "classnames";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

const PaymentMethods: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.paymentMethodsContainer, className)}>
      <div>
        <div className={styles.paymentMethodsLabel}>
          {t("containers.pricing.costSummary.paymentMethodsAccepted")}
        </div>

        <div className={styles.paymentIcons}>
          <Image src="/images/payment-methods.png" width="246" height="38" />
        </div>
      </div>

      <div>
        <div className={styles.paymentMethodsMoneyBackGuarantee}>
          {t("containers.pricing.costSummary.moneyBackGuarantee")}
        </div>

        <div className={styles.moneyBack}>
          <Image src="/images/money-back.png" width="100" height="100" />
        </div>

        <div className={styles.paymentMethodsMoneyBackGuaranteeNote}>
          {t("containers.pricing.costSummary.refundPolicy")}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
