import React, { SVGProps } from "react";

const IconToggle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3H11.5C6.8 3.2 3 7.2 3 12C3 17 7 21 12 21C16.8 21 20.8 17.2 21 12.5V11.5C20.8 6.9 17.1 3.2 12.5 3ZM10 17L6 13L7.4 11.6L10 14.2L16.6 7.6L18 9L10 17Z"
      fill="currentColor"
    />
  </svg>
);

export default IconToggle;
