import useTranslation from "next-translate/useTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useWindowSize } from "react-use";

import { useAppDispatch, useAppSelector } from "stores";
import {
  previewUpdated,
  selectPreview,
} from "stores/features/appBuilder/preview";
import { selectIsLeftNavCollapsed } from "stores/features/ui";
import api from "utils/api";
import { handleError } from "utils/errors";
import { useMobile } from "utils/hooks";

import { BuildButtonType, useIncrementalBuild } from "./BuildButton/utils";
import { Device, DeviceType } from "./constants";

export const useSimulatorPreview = () => {
  const dispatch = useAppDispatch();
  const { currentDeviceType } = useAppSelector(selectPreview);

  const setCurrentDeviceType = useCallback(
    (nextDeviceType: DeviceType) => {
      dispatch(previewUpdated({ currentDeviceType: nextDeviceType }));
    },
    [dispatch]
  );

  const type: BuildButtonType = useMemo(
    () =>
      [DeviceType.iphone, DeviceType.ipad, DeviceType.otherIos].includes(
        currentDeviceType
      )
        ? "iosAppetize"
        : "androidAppetize",
    [currentDeviceType]
  );

  const { builderConfig } = useIncrementalBuild({ type });

  return {
    currentBuilderConfig: builderConfig,
    currentDeviceType,
    setCurrentDeviceType,
  };
};

export const useIsPreviewFullScreen = (device: Device, zoom: number) => {
  const isLeftNavCollapsed = useAppSelector(selectIsLeftNavCollapsed);
  const isMobile = useMobile();
  const windowSize = useWindowSize();

  const previewWidth =
    device.orientation === "portrait"
      ? device.dimensions.portrait.width
      : device.dimensions.landscape.width;

  let excessWidth = windowSize.width - (previewWidth * zoom) / 100;
  excessWidth -= isLeftNavCollapsed ? 70 : 220;

  return isMobile || excessWidth < 250;
};

export const useAppetizeMediaUpload = (device: Device) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const { connectionString, sessionToken } = device;

        if (
          acceptedFiles.length === 0 ||
          !sessionToken ||
          !connectionString ||
          isUploading
        ) {
          return;
        }

        setIsUploading(true);

        await api.appetize.addMedia({
          file: acceptedFiles[0],
          connectionString,
          sessionToken,
        });

        toast(t("containers.rightPanel.preview.mediaUploaded"), {
          type: "success",
        });
      } catch (e) {
        handleError(e, { t });
      } finally {
        setIsUploading(false);
      }
    },
    [device, isUploading, t]
  );

  const dropzone = useDropzone({
    accept: {
      "image/jpg": [".png", ".jpeg", ".jpg"],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: handleUpload,
    noClick: true,
    noKeyboard: true,
  });

  return {
    disabled: !device.sessionToken || !device.connectionString,
    handleUpload: () => dropzone.open(),
    isUploading,
  };
};

export const useDebug = (device: Device) => {
  const { appetizeAndroidPublicKey, appetizePublicKey } =
    useAppSelector(selectPreview);
  const publicKey =
    device.os === "ios" ? appetizePublicKey : appetizeAndroidPublicKey;

  return `https://appetize.io/app/${publicKey}?device=${device.model}&scale=75&deviceColor=black&osVersion=${device.osVersion}&debug=true`;
};

export function allowVPN(created: string | undefined) {
  const timeDifference = Date.now() - new Date(created || 0).getTime();
  const minutesDifference = timeDifference / (1000 * 60);
  return minutesDifference >= 0 && minutesDifference < 45;
}

export const useCountdownTimer = () => {
  const [timers, setTimers] = useState<{ [key: string]: number }>({});
  const timerRefs = useRef<{ [key: string]: NodeJS.Timer }>({});

  const startCountdown = useCallback(
    (key: string, seconds: number, onComplete: () => void) => {
      // Clear any existing timer for the same key
      clearInterval(timerRefs.current[key]);

      // Initialize the countdown
      setTimers((prevTimers) => ({ ...prevTimers, [key]: seconds }));

      // Start the countdown interval
      timerRefs.current[key] = setInterval(() => {
        setTimers((prevTimers) => {
          const currentTimer = prevTimers[key];
          if (!currentTimer) return prevTimers;

          if (currentTimer <= 1) {
            clearInterval(timerRefs.current[key]);
            onComplete();
            const nextTimers = { ...prevTimers };
            delete nextTimers[key];
            return nextTimers;
          }

          return { ...prevTimers, [key]: currentTimer - 1 };
        });
      }, 1000);
    },
    []
  );

  const stopCountdown = useCallback((key: string) => {
    clearInterval(timerRefs.current[key]);
  }, []);

  useEffect(
    () => () => {
      Object.keys(timerRefs.current).forEach((key) => {
        clearInterval(timerRefs.current[key]);
      });
    },
    []
  );

  return {
    timers,
    startCountdown,
    stopCountdown,
  };
};
