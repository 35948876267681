import classNames from "classnames";
import dynamic from "next/dynamic";
import React, { useEffect, useRef } from "react";

import Loader from "components/common/Loader";
import { ORGSECTION, SECTION } from "utils/constants";
import { useAppSection, useOrgSection } from "utils/hooks";
import useIsInOrganization from "utils/hooks/useIsInOrganization";

import { useCentralizedBuilder } from "../RightPanel/Preview/BuildButton/utils";
import styles from "./styles.module.scss";

const Access = dynamic(() => import("components/sections/Access"), {
  loading: () => <Loader className={styles.loader} />,
});
const Branding = dynamic(() => import("components/sections/Branding"), {
  loading: () => <Loader className={styles.loader} />,
});
const Build = dynamic(() => import("components/sections/Build"), {
  loading: () => <Loader className={styles.loader} />,
  ssr: true,
});
const Dashboard = dynamic(() => import("components/sections/Dashboard"), {
  loading: () => <Loader className={styles.loader} />,
});
const Interface = dynamic(() => import("components/sections/Interface"), {
  loading: () => <Loader className={styles.loader} />,
});
const License = dynamic(() => import("components/sections/License"), {
  loading: () => <Loader className={styles.loader} />,
});
const LinkHandling = dynamic(() => import("components/sections/LinkHandling"), {
  loading: () => <Loader className={styles.loader} />,
});
const NativeNavigation = dynamic(
  () => import("components/sections/NativeNavigation"),
  {
    loading: () => <Loader className={styles.loader} />,
  }
);
const NativePlugins = dynamic(
  () => import("components/sections/NativePlugins"),
  {
    loading: () => <Loader className={styles.loader} />,
  }
);
const Overview = dynamic(() => import("components/sections/Overview"), {
  loading: () => <Loader className={styles.loader} />,
});
const Permissions = dynamic(() => import("components/sections/Permissions"), {
  loading: () => <Loader className={styles.loader} />,
});
const PushNotifications = dynamic(
  () => import("components/sections/PushNotifications"),
  {
    loading: () => <Loader className={styles.loader} />,
  }
);
const Services = dynamic(() => import("components/sections/Services"), {
  loading: () => <Loader className={styles.loader} />,
});
const Support = dynamic(() => import("components/sections/Support"), {
  loading: () => <Loader className={styles.loader} />,
});
const WebsiteOverrides = dynamic(
  () => import("components/sections/WebsiteOverrides"),
  {
    loading: () => <Loader className={styles.loader} />,
  }
);

const Apps = dynamic(() => import("components/sections/Apps"), {
  loading: () => <Loader className={styles.loader} />,
});
const Members = dynamic(() => import("components/sections/Members"), {
  loading: () => <Loader className={styles.loader} />,
});
const Settings = dynamic(() => import("components/sections/Settings"), {
  loading: () => <Loader className={styles.loader} />,
});

type SectionComponents = {
  [key in SECTION]?: React.ReactNode;
};

type OrgSectionComponents = {
  [key in ORGSECTION]?: React.ReactNode;
};

const sections: SectionComponents = {
  manage: <Dashboard />,
  overview: <Overview />,
  branding: <Branding />,
  interface: <Interface />,
  linkHandling: <LinkHandling />,
  permissions: <Permissions />,
  websiteOverrides: <WebsiteOverrides />,
  nativeNavigation: <NativeNavigation />,
  pushNotifications: <PushNotifications />,
  nativePlugins: <NativePlugins />,
  build: <Build />,
  annualPlan: <Support />,
  services: <Services />,
  license: <License />,
  access: <Access />,
};

const orgSections: OrgSectionComponents = {
  apps: <Apps />,
  members: <Members />,
  settings: <Settings />,
};

const Main: React.FC = () => {
  useCentralizedBuilder();

  const [appSection] = useAppSection();
  const [orgSection] = useOrgSection();

  const isInOrganizationPage = useIsInOrganization();

  const mainRef = useRef<HTMLElement>(null);

  const scrollPositions = useRef<{ [key: string]: number }>({});

  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current) {
        scrollPositions.current[appSection] = mainRef.current.scrollTop;
      }
    };

    const { current } = mainRef;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [appSection]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (mainRef.current) {
        mainRef.current.scrollTop = scrollPositions.current[appSection] || 0;
      }
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [appSection]);

  return (
    <main
      className={classNames(
        styles.main,
        appSection === SECTION.manage &&
          !isInOrganizationPage &&
          styles.dashboard,
        isInOrganizationPage && styles.organization
      )}
      id="__main-content"
      ref={mainRef}
    >
      {isInOrganizationPage ? orgSections[orgSection] : sections[appSection]}
    </main>
  );
};

export default Main;
