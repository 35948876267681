import isString from "lodash/isString";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconAdd from "assets/images/icons/IconAdd";
import IconRemove from "assets/images/icons/remove.svg";
import InfoPopup from "components/common/InfoPopup";
import { formatPrice } from "utils/formatting";

import styles from "./styles.module.scss";

interface Props {
  label: string;
  onRemove?: () => void;
  price?: number | string;
  removable?: boolean;
  onAdd?: () => void;
}

const CostSummaryItem: React.FC<Props> = ({
  label,
  onRemove,
  onAdd,
  price,
  removable,
}) => {
  const { lang } = useTranslation();

  const i18nKey = removable ? `${label} <button></button>` : label;

  if (onAdd && typeof onAdd === "function")
    return (
      <div className={styles.costSummaryItem}>
        <div className={styles.costSummaryLabel}>
          <button
            className={styles.costSummaryAddItemButton}
            onClick={onAdd}
            type="button"
          >
            {i18nKey}
            <IconAdd />
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.costSummaryItem}>
      <div className={styles.costSummaryLabel}>
        <Trans
          components={{
            button: (
              <button
                className={styles.costSummaryItemButton}
                onClick={onRemove}
                type="button"
              >
                <IconRemove />
              </button>
            ),
            info: <InfoPopup iconColor="#1A100B">placeholder</InfoPopup>,
          }}
          i18nKey={i18nKey}
        />
      </div>

      {price !== undefined ? (
        <div className={styles.costSummaryItemPrice}>
          {isString(price) ? price : formatPrice(lang, price)}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default CostSummaryItem;
