import cloneDeep from "lodash/cloneDeep";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useState } from "react";

import IconBookmark from "assets/images/icons/IconBookmark";
import IconBookmarked from "assets/images/icons/IconBookmarked";
import IconEdit from "assets/images/icons/IconEdit";
import IconEye from "assets/images/icons/IconEye";
import IconRestoreFlipped from "assets/images/icons/IconRestoreFlipped";
import IconToggle from "assets/images/icons/IconToggle";
import Button from "components/common/Button";
import Input from "components/common/Input";
import Loader from "components/common/Loader";
import Pill from "components/common/Pill";
import PopConfirm from "components/common/PopConfirm";
import LastModifiedUser from "components/containers/TopBar/LastModifiedUser";
import {
  BackupItem,
  useRenameBackupMutation,
  useRestoreBackupMutation,
  useSaveBackupMutation,
  useUnsaveBackupMutation,
} from "services/api/backups";
import { useAppDispatch, useAppSelector } from "stores";
import { backupsUpdated, selectBackupsList } from "stores/features/backups";
import { selectPrivateKey } from "stores/features/project";
import { trackEvent } from "utils/analytics";
import { handleError } from "utils/errors";
import urls from "utils/urls";

import styles from "./styles.module.scss";

interface Props {
  backupItem: BackupItem;
  index: number;
  onChange?: (id: string | number, output: BackupItem) => void;
}

const ManageBackupItem: React.FC<Props> = ({ backupItem, index }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const backups = useAppSelector(selectBackupsList);
  const privateKey = useAppSelector(selectPrivateKey);
  const [saveBackup, { isLoading: isSaving }] = useSaveBackupMutation();
  const [unsaveBackup, { isLoading: isUnsaving }] = useUnsaveBackupMutation();
  const [renameBackup, { isLoading: isRenaming }] = useRenameBackupMutation();
  const [restoreBackup, { isLoading: isRestoring }] =
    useRestoreBackupMutation();

  const {
    version = 0,
    created,
    name = "",
    isManuallySaved,
    isModifiedBySuperUser,
    isModifiedByDeletedUser,
    modifiedBy,
  } = backupItem;

  const [isEditing, setIsEditing] = useState(false);
  const [backupName, setBackupName] = useState(name);

  const isLoading = isSaving || isUnsaving || isRenaming;

  const createdDateTime = useMemo(
    () => new Date(created).toString().split(" ").slice(0, 6).join(" "),
    [created]
  );

  const previewUrl = useMemo(
    () => urls.backupUrl(privateKey, version),
    [privateKey, version]
  );

  const handlePreviewBackup = useCallback(() => {
    trackEvent("backups_preview");
    window.open(previewUrl, "_blank");
  }, [previewUrl]);

  const handleRestoreBackup = useCallback(async () => {
    try {
      if (isRestoring) return;

      await restoreBackup({
        privateKey,
        versionNumber: version,
      });

      trackEvent("backups_restore");

      router.reload();
    } catch (e) {
      handleError(e, { t });
    }
  }, [isRestoring, privateKey, restoreBackup, router, t, version]);

  const handleSaveBackup = useCallback(() => {
    try {
      if (isManuallySaved) {
        unsaveBackup({ privateKey, versionNumber: version });
      } else {
        saveBackup({ privateKey, versionNumber: version });
        trackEvent("backups_save");
      }

      const nextBackups = cloneDeep(backups);
      nextBackups[index].isManuallySaved = !isManuallySaved;
      dispatch(backupsUpdated({ backups: nextBackups }));
    } catch (e) {
      handleError(e, { t });
    }
  }, [
    isManuallySaved,
    backups,
    index,
    dispatch,
    unsaveBackup,
    privateKey,
    version,
    saveBackup,
    t,
  ]);

  const handleSaveName = useCallback(async () => {
    try {
      setIsEditing(false);

      if (name === backupName) return;

      const nextBackups = cloneDeep(backups);
      nextBackups[index].name = backupName;
      dispatch(backupsUpdated({ backups: nextBackups }));

      await renameBackup({
        privateKey,
        versionNumber: version,
        name: backupName,
      });
    } catch (e) {
      handleError(e, { t });
    }
  }, [
    backupName,
    backups,
    dispatch,
    index,
    name,
    privateKey,
    renameBackup,
    t,
    version,
  ]);

  const isActive = index === 0;

  return (
    <div className={styles.backupItemContainer}>
      <form className={styles.leftContainer}>
        <div className={styles.backupItemTitleDate}>
          {isEditing ? (
            <Input
              className={styles.input}
              onChange={(e) => setBackupName(e.value)}
              placeholder={t("sections.overview.backupNamePlaceholder")}
              type="text"
              value={backupName}
              maxLength={40}
            />
          ) : (
            <div className={styles.backupDetails}>
              <div className={styles.backupItemTitle}>
                {name}

                {isActive && (
                  <Pill className={styles.pill} type="enabled">
                    {t("common.current")}
                  </Pill>
                )}
              </div>

              <LastModifiedUser
                className={styles.backupItemDate}
                components={{
                  date: <span>{createdDateTime}</span>,
                }}
                i18nKey="sections.overview.backupDateByUser"
                i18nKeyWithoutUser="sections.overview.backupDate"
                isDeleted={isModifiedByDeletedUser}
                isSuperUser={isModifiedBySuperUser}
                name={modifiedBy}
              />
            </div>
          )}
        </div>

        <div className={styles.saveAndEditIcons}>
          {isLoading && <Loader className={styles.loader} size="md" />}

          {isEditing && !isLoading && (
            <Button
              disabled={name.length <= 1}
              className={styles.editIcon}
              size="sm"
              onClick={handleSaveName}
              isSubmit
            >
              <IconToggle />
            </Button>
          )}

          {!isEditing && !isLoading && (
            <Button
              className={styles.editIcon}
              disabled={!name}
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              <IconEdit width={21} height={21} color="#1A100B" />
            </Button>
          )}

          {!isEditing && !isLoading && (
            <Button
              className={styles.icon}
              size="sm"
              onClick={handleSaveBackup}
            >
              {isManuallySaved ? <IconBookmarked /> : <IconBookmark />}
            </Button>
          )}
        </div>
      </form>
      <div className={styles.backupItemButtons}>
        <Button
          className={styles.restoreButton}
          size="sm"
          onClick={handlePreviewBackup}
          type="outlined"
          disabled={isActive}
        >
          <IconEye height={20} width={19} />
          {t("common.preview")}
        </Button>

        <PopConfirm
          confirmText={t("common.confirm")}
          onConfirm={handleRestoreBackup}
          title={t("sections.overview.confirmRestoreBackup")}
          disabled={isActive}
        >
          <Button
            className={styles.restoreButton}
            disabled={isActive}
            size="sm"
            type="primary"
          >
            <IconRestoreFlipped />
            {t("common.restore")}
          </Button>
        </PopConfirm>
      </div>
    </div>
  );
};

export default ManageBackupItem;
