import { useRouter } from "next/router";

import useOrganizationDetails from "./useOrganizationDetails";

const useIsInOrganization = () => {
  const { organizationId } = useOrganizationDetails();
  const router = useRouter();
  const { pathname } = router;
  const isInOrganization = pathname.startsWith("/organization");

  return organizationId && isInOrganization;
};

export default useIsInOrganization;
