import React, { SVGProps } from "react";

const IconPreview: React.FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.25 5.25H20.25V8.25H17.25V5.25ZM17.25 9.75H20.25V12.75H17.25V9.75ZM12.75 5.25H15.75V8.25H12.75V5.25ZM12.75 9.75H15.75V12.75H12.75V9.75Z"
      fill="white"
    />
    <path
      d="M10.875 19.5C11.4963 19.5 12 18.9963 12 18.375C12 17.7537 11.4963 17.25 10.875 17.25C10.2537 17.25 9.75 17.7537 9.75 18.375C9.75 18.9963 10.2537 19.5 10.875 19.5Z"
      fill="currentColor"
    />
    <path
      d="M15.75 22.5H6C5.6023 22.4996 5.221 22.3414 4.93978 22.0602C4.65856 21.779 4.5004 21.3977 4.5 21V3C4.5004 2.6023 4.65856 2.221 4.93978 1.93978C5.221 1.65856 5.6023 1.5004 6 1.5H15.75V3H6V21H15.75V15H17.25V21C17.2496 21.3977 17.0914 21.779 16.8102 22.0602C16.529 22.3414 16.1477 22.4996 15.75 22.5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconPreview;
