import React, { SVGProps } from "react";

const IconEye: React.FC<SVGProps<SVGSVGElement>> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4023 9.49609C16.5508 5.5957 13.7519 3.63281 9.99999 3.63281C6.24608 3.63281 3.44921 5.5957 1.59765 9.49805C1.52338 9.65531 1.48486 9.82706 1.48486 10.001C1.48486 10.1749 1.52338 10.3466 1.59765 10.5039C3.44921 14.4043 6.24804 16.3672 9.99999 16.3672C13.7539 16.3672 16.5508 14.4043 18.4023 10.502C18.5527 10.1855 18.5527 9.81836 18.4023 9.49609V9.49609ZM9.99999 14.9609C6.8496 14.9609 4.54296 13.3633 2.91601 10C4.54296 6.63672 6.8496 5.03906 9.99999 5.03906C13.1504 5.03906 15.457 6.63672 17.084 10C15.459 13.3633 13.1523 14.9609 9.99999 14.9609ZM9.92187 6.5625C8.02343 6.5625 6.48437 8.10156 6.48437 10C6.48437 11.8984 8.02343 13.4375 9.92187 13.4375C11.8203 13.4375 13.3594 11.8984 13.3594 10C13.3594 8.10156 11.8203 6.5625 9.92187 6.5625ZM9.92187 12.1875C8.71288 12.1875 7.73437 11.209 7.73437 10C7.73437 8.79102 8.71288 7.8125 9.92187 7.8125C11.1308 7.8125 12.1094 8.79102 12.1094 10C12.1094 11.209 11.1308 12.1875 9.92187 12.1875Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEye;
