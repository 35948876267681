/* eslint-disable react/jsx-no-useless-fragment */

import classNames from "classnames";
import { TransProps } from "next-translate";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useMemo } from "react";

import styles from "./styles.module.scss";

interface Props extends TransProps {
  className?: string;
  i18nKeyWithoutUser?: string;
  isDeleted?: boolean;
  isSuperUser?: boolean;
  name?: string;
}

const LastModifiedUser: React.FC<Props> = ({
  className,
  components,
  i18nKey,
  i18nKeyWithoutUser,
  isDeleted,
  isSuperUser,
  name,
}) => {
  const { t } = useTranslation();

  const internalName = useMemo(() => {
    if (isSuperUser) {
      if (name) {
        return t("containers.topBar.medianSupportUser", { name });
      }
      return t("containers.topBar.medianSupport");
    }
    return name;
  }, [isSuperUser, name, t]);

  const renderName = useMemo(() => {
    if (!internalName) {
      return undefined;
    }
    const styledName = isDeleted ? `[${internalName}]` : internalName;
    return (
      <span className={classNames(isDeleted && styles.deleted)}>
        {styledName}
      </span>
    );
  }, [internalName, isDeleted]);

  return (
    <div className={classNames(styles.container, className)}>
      <Trans
        components={{
          ...components,
          br: <br />,
          name: <>{renderName}</>,
        }}
        i18nKey={internalName ? i18nKey : i18nKeyWithoutUser || i18nKey}
      />
    </div>
  );
};

export default LastModifiedUser;
