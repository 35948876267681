/* eslint-disable no-underscore-dangle */

import useTranslation from "next-translate/useTranslation";
import React from "react";

import Loader from "components/common/Loader";
import { useAppSelector } from "stores";
import { selectBackupsList } from "stores/features/backups";

import { useBackups } from "../utils";
import ManageBackupItem from "./ManageBackupItem";
import styles from "./styles.module.scss";

const ManageBackups: React.FC = () => {
  const backups = useAppSelector(selectBackupsList);
  const { t } = useTranslation();
  const { isFetching } = useBackups();

  return (
    <div className={styles.modalContent}>
      {isFetching && backups.length === 0 && (
        <Loader className={styles.fetchingBackups} />
      )}

      {!isFetching && backups.length === 0 && (
        <div className={styles.emptyBackupContainer}>
          <div className={styles.backupItemTitle}>
            {t("sections.overview.emptyBackup")}
          </div>
        </div>
      )}

      {backups.map((item, index) => (
        <ManageBackupItem backupItem={item} index={index} key={item._id} />
      ))}
    </div>
  );
};

export default ManageBackups;
