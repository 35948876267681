/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */

import classNames from "classnames";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import IconBilling from "assets/images/icons/IconBilling";
import IconImport from "assets/images/icons/IconImport";
import IconLeft from "assets/images/icons/IconLeft.svg";
import IconRight from "assets/images/icons/IconRight.svg";
import IconTransfer from "assets/images/icons/IconTransfer";
import Button from "components/common/Button";
import RelativeDistance from "components/common/RelativeDistance";
import SelectButton from "components/common/Select/SelectButton";
import ToastMessage from "components/common/ToastMessage";
import Tooltip from "components/common/Tooltip";
import ModalOutdatedConfig from "components/sections/Build/ModalOutdatedConfig";
import ModalManageBackups from "components/sections/Overview/ModalManageBackups";
import { useBackups } from "components/sections/Overview/utils";
import { BackupItem, useRestoreBackupMutation } from "services/api/backups";
import {
  useLazyGetProjectQuery,
  useLazyGetProjectStatusQuery,
} from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import { defaultAppIcon } from "stores/constants";
import { selectAppAccess } from "stores/features/app";
import { selectAppIcon } from "stores/features/appBuilder/branding";
import { selectLicense } from "stores/features/appBuilder/license";
import {
  selectAppName,
  selectOverview,
} from "stores/features/appBuilder/overview";
import {
  appBuilderDiscarded,
  selectAppBuilder,
} from "stores/features/appBuilder/utils";
import { selectIsBackupPreview } from "stores/features/backups";
import { selectIsCartLoaded } from "stores/features/cart";
import {
  hasChangeAndroidOrIosKeys,
  metaUpdated,
  selectBuildChanges,
  selectBuilderDiff,
  selectCachedAppBuilder,
  selectHasChanges,
  selectMeta,
} from "stores/features/meta";
import {
  organizationUpdated,
  selectOrganizationName,
} from "stores/features/organization";
import {
  selectCurrentAppOrgOwner,
  selectPrivateKey,
  selectPublicKey,
} from "stores/features/project";
import {
  selectImportFomApp,
  selectIsLeftNavCollapsed,
  selectIsRightPanelCollapsed,
  selectLastSavedTime,
  selectRebuildOnSave,
  uiUpdated,
} from "stores/features/ui";
import { selectIsAuthenticated } from "stores/features/user";
import { trackEvent } from "utils/analytics";
import { BuilderConfigInput } from "utils/appBuilder";
import { SECTION } from "utils/constants";
import { errorMatchesMessage, handleError } from "utils/errors";
import {
  useAppSection,
  useImage,
  useIsAppBuilderChanged,
  useMobile,
  usePhone,
  useSocketIo,
} from "utils/hooks";
import useAppBuilderValidator from "utils/hooks/useAppBuilderValidator";
import urls, { toRelativeUrl } from "utils/urls";

import PurchaseSummaryBar from "../Pricing/PurchaseSummaryBar";
import {
  useBuildIssues,
  useValidateRegexValues,
} from "../RightPanel/Preview/BuildButton/utils";
import { DeviceType } from "../RightPanel/Preview/constants";
import { useSimulatorPreview } from "../RightPanel/Preview/utils";
import { topBarCartSections } from "../RightPanel/utils";
import IconComponent from "./icons/component.svg";
import IconClaim from "./images/claim.svg";
import IconClone from "./images/clone.svg";
import IconHistory from "./images/history.svg";
import IconPreview from "./images/IconPreview";
import IconShare from "./images/share.svg";
import LastModifiedUser from "./LastModifiedUser";
import styles from "./styles.module.scss";
import { useProjectTimestamp, useSaveProject } from "./utils";

interface Props {
  readonly?: boolean;
  backupItem?: BackupItem;
  type?: "appbuilder" | "organization";
}

const TopBar: React.FC<Props> = ({ readonly, backupItem, type }) => {
  const { buildProject, saveProject } = useSaveProject();
  const [getProject] = useLazyGetProjectQuery();

  const { validateAppBuilder } = useAppBuilderValidator();
  const dispatch = useAppDispatch();
  const isLeftNavCollapsed = useAppSelector(selectIsLeftNavCollapsed);
  const isRightPanelCollapsed = useAppSelector(selectIsRightPanelCollapsed);
  const overview = useAppSelector(selectOverview);
  const appBuilder = useAppSelector(selectAppBuilder);
  const license = useAppSelector(selectLicense);
  const buildChanges = useAppSelector(selectBuildChanges);
  const buildDiff = useAppSelector(selectBuilderDiff);
  const cachedAppBuilder = useAppSelector(selectCachedAppBuilder);
  const hasChanges = useAppSelector(selectHasChanges);
  const overviewAppName = useAppSelector(selectAppName);
  const privateKey = useAppSelector(selectPrivateKey);
  const publicKey = useAppSelector(selectPublicKey);
  const { currentDeviceType } = useSimulatorPreview();
  const organizationName = useAppSelector(selectOrganizationName);
  const { t } = useTranslation();
  const {
    showGoogleServicesJsonError,
    showSplashIconsWarning,
    showUniversalLinksWarning,
    showOneSignalAppIdError,
    showUrlSchemeProtocolError,
    showAppTrackingTransparencyError,
  } = useBuildIssues();
  const { showInvalidRegex } = useValidateRegexValues();
  const {
    isModifiedByDeletedUser,
    isModifiedBySuperUser,
    lastModifiedBy,
    lastModifiedDate,
  } = useProjectTimestamp();
  const [restoreBackup, { isLoading: isRestoring }] =
    useRestoreBackupMutation();
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const access = useAppSelector(selectAppAccess);
  const [appSection] = useAppSection();
  const [isSaving, setIsSaving] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isDoneEditing, setIsDoneEditing] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isCartLoaded = useAppSelector(selectIsCartLoaded);
  const isMobile = useMobile();
  const isPhone = usePhone();
  const router = useRouter();
  const { fetchBackups } = useBackups();
  const appAccess = useAppSelector(selectAppAccess);
  const importFromApp = useAppSelector(selectImportFomApp);

  const lastSavedTime = useAppSelector(selectLastSavedTime);

  const { isAppBuilderChanged } = useIsAppBuilderChanged();

  const [isOutdatedConfigModalOpen, setIsOutdatedConfigModalOpen] =
    useState(false);
  const { socket, connectionError } = useSocketIo();

  const appIcon = useImage(useAppSelector(selectAppIcon));

  const isOrgOwner = useAppSelector(selectCurrentAppOrgOwner);
  const rebuildOnSave = useAppSelector(selectRebuildOnSave);

  const { isBuildDownloadsBlocked: isDownloadsBlocked } =
    useAppSelector(selectMeta);

  const hasChangesPlatform = hasChangeAndroidOrIosKeys(buildDiff);

  const [isToastActive, setIsToastActive] = useState(false);

  const [getProjectStatus] = useLazyGetProjectStatusQuery();

  const toastId = 1;

  const menuOptions = useMemo(
    () =>
      overview.organizationName
        ? ["admin", "developer"].includes(appAccess)
          ? [
              {
                label: t("containers.topBar.sharePublicLink"),
                icon: <IconShare />,
                value: "share-app",
              },
              ...(!isDownloadsBlocked
                ? [
                    {
                      label: t("containers.topBar.cloneApp"),
                      icon: <IconClone />,
                      value: "clone-app",
                    },
                  ]
                : []),
              {
                label: t("containers.topBar.importFromApp"),
                icon: <IconImport />,
                value: "importFromApp",
              },
              {
                label: t("containers.topBar.manageBackups"),
                icon: <IconHistory />,
                value: "manage-backups",
              },
              ...(license.app.subscriptionManagementLink
                ? [
                    {
                      label: t("containers.topBar.manageSubscription"),
                      icon: <IconBilling />,
                      value: "manageSubscription",
                    },
                  ]
                : []),
              ...(isOrgOwner
                ? [
                    {
                      label: t("containers.topBar.transferApp"),
                      icon: <IconTransfer />,
                      value: "transferApp",
                    },
                  ]
                : []),
            ]
          : [
              {
                label: t("containers.topBar.sharePublicLink"),
                icon: <IconShare />,
                value: "share-app",
              },
              ...(!isDownloadsBlocked
                ? [
                    {
                      label: t("containers.topBar.cloneApp"),
                      icon: <IconClone />,
                      value: "clone-app",
                    },
                  ]
                : []),
              {
                label: t("containers.topBar.manageBackups"),
                icon: <IconHistory />,
                value: "manage-backups",
              },
            ]
        : [
            {
              label: t("containers.topBar.sharePublicLink"),
              icon: <IconShare />,
              value: "share-app",
            },
            ...(!isDownloadsBlocked
              ? [
                  {
                    label: t("containers.topBar.cloneApp"),
                    icon: <IconClone />,
                    value: "clone-app",
                  },
                ]
              : []),
            {
              label: t("containers.topBar.manageBackups"),
              icon: <IconHistory />,
              value: "manage-backups",
            },
            {
              label: t("containers.topBar.claimApp"),
              icon: <IconClaim />,
              value: "claim-app",
            },
          ],
    [
      appAccess,
      isDownloadsBlocked,
      isOrgOwner,
      license.app.subscriptionManagementLink,
      overview.organizationName,
      t,
    ]
  );

  const saveBuilderConfig: BuilderConfigInput = useMemo(() => {
    if (!rebuildOnSave) {
      switch (hasChangesPlatform) {
        case "ios":
          return {
            platform: "ios",
            status: "draft",
            type: {
              appetize: "expired",
            },
            hasChanges: "ios",
          };
          break;
        case "android":
          return {
            platform: "android",
            status: "draft",
            type: {
              appetize: "expired",
            },
            hasChanges: "android",
          };
          break;
        case "ios&android":
          return {
            platform: [DeviceType.ipad, DeviceType.iphone].includes(
              currentDeviceType
            )
              ? "ios"
              : "android",
            status: "draft",
            type: {
              appetize: "expired",
            },
            hasChanges: "ios&android",
          };
          break;
        default:
          return {
            platform: [DeviceType.ipad, DeviceType.iphone].includes(
              currentDeviceType
            )
              ? "ios"
              : "android",
            status: "draft",
            type: { appetize: "expired" },
            hasChanges: "ios&android",
          };
      }
    } else {
      switch (hasChangesPlatform) {
        case "ios":
          return {
            platform: "ios",
            status: [DeviceType.ipad, DeviceType.iphone].includes(
              currentDeviceType
            )
              ? "queued"
              : "draft",
            type: {
              appetize: [DeviceType.ipad, DeviceType.iphone].includes(
                currentDeviceType
              )
                ? "buildRequested"
                : "expired",
            },
            hasChanges: "ios",
          };
          break;
        case "android":
          return {
            platform: "android",
            status: [
              DeviceType.androidPhone,
              DeviceType.androidTablet,
            ].includes(currentDeviceType)
              ? "queued"
              : "draft",
            type: {
              appetize: [
                DeviceType.androidPhone,
                DeviceType.androidTablet,
              ].includes(currentDeviceType)
                ? "buildRequested"
                : "expired",
            },
            hasChanges: "android",
          };
          break;
        case "ios&android":
          return {
            platform: [DeviceType.ipad, DeviceType.iphone].includes(
              currentDeviceType
            )
              ? "ios"
              : "android",
            status: "queued",
            type: { appetize: "buildRequested" },
            hasChanges: "ios&android",
          };
          break;
        default:
          return {
            platform: [DeviceType.ipad, DeviceType.iphone].includes(
              currentDeviceType
            )
              ? "ios"
              : "android",
            status: "queued",
            type: { appetize: "buildRequested" },
            hasChanges: "ios&android",
          };
      }
    }
  }, [currentDeviceType, hasChangesPlatform, rebuildOnSave]);

  const backup = useMemo(
    () => ({
      name: backupItem?.name || "",
      date: new Date(backupItem?.created || "").toLocaleString(),
      modifiedBy: backupItem?.modifiedBy || "",
      isModifiedByDeletedUser: backupItem?.isModifiedByDeletedUser,
      isModifiedBySuperUser: backupItem?.isModifiedBySuperUser,
    }),
    [backupItem]
  );

  const handleDiscard = useCallback(async () => {
    try {
      if (!cachedAppBuilder || !privateKey) return;
      setIsDiscarding(true);

      if (isAppBuilderChanged) {
        await getProject({ privateKey, headers: {} }).unwrap();
        await getProjectStatus({ privateKey, headers: {} }).unwrap();
        const newDate = new Date().toISOString();
        dispatch(uiUpdated({ localLastSavedTime: newDate }));
        toast.dismiss();
      } else {
        dispatch(appBuilderDiscarded(cachedAppBuilder));
        dispatch(metaUpdated({ appBuilderErrors: {}, pendingUploads: {} }));
        dispatch(uiUpdated({ splashScreen: { ios: {}, android: {} } }));
      }
    } catch (e) {
    } finally {
      setIsDiscarding(false);
    }
  }, [
    cachedAppBuilder,
    dispatch,
    getProject,
    getProjectStatus,
    isAppBuilderChanged,
    privateKey,
  ]);

  const handleDoneEditing = useCallback(async () => {
    if (isDoneEditing) return;
    setIsDoneEditing(true);
    try {
      if (isAppBuilderChanged) {
        await getProject({ privateKey, headers: {} }).unwrap();
        await getProjectStatus({ privateKey, headers: {} }).unwrap();
        const newDate = new Date().toISOString();
        dispatch(uiUpdated({ localLastSavedTime: newDate }));
        toast.dismiss();
      }
      router.push(urls.appBuilder(privateKey), undefined, {
        shallow: true,
      });
    } catch (e) {
    } finally {
      setIsDoneEditing(false);
    }
  }, [
    dispatch,
    getProject,
    getProjectStatus,
    isAppBuilderChanged,
    isDoneEditing,
    privateKey,
    router,
  ]);

  const handleSave = useCallback(async () => {
    if (isSaving || !privateKey) return;

    setIsSaving(true);
    if (isAppBuilderChanged && !isOutdatedConfigModalOpen) {
      setIsSaving(false);
      setIsOutdatedConfigModalOpen(true);
      return;
    }

    try {
      if (showGoogleServicesJsonError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.build,
            undefined,
            "google-services"
          ),
          undefined,
          {
            shallow: true,
          }
        );
        return;
      }
      if (showOneSignalAppIdError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.pushNotifications,
            undefined,
            "application-id"
          ),
          undefined,
          {
            shallow: true,
          }
        );

        return;
      }
      if (showAppTrackingTransparencyError()) {
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.permissions,
            undefined,
            "apple-att"
          ),
          undefined,
          {
            shallow: true,
          }
        );

        return;
      }

      showSplashIconsWarning();
      showUniversalLinksWarning();
      if (showInvalidRegex()) return;
      if (showUrlSchemeProtocolError()) return;

      if (await validateAppBuilder()) {
        // Rebuild Appetize
        if (buildChanges) {
          await buildProject({
            privateKey,
            data: {
              appBuilder,
              builderConfig: saveBuilderConfig,
            },
          });
        } else {
          await saveProject({ privateKey, data: { appBuilder } });
        }
      }
      if (socket && !connectionError) {
        const newDate = new Date().toISOString();
        dispatch(uiUpdated({ localLastSavedTime: newDate }));
        socket.emit("save", privateKey, newDate);
        toast.dismiss();
      }
    } catch (e) {
      if (errorMatchesMessage(e, "not-loggedin")) {
        dispatch(
          uiUpdated({
            authModalType: "signin/claimApp",
            progressiveLogin: true,
          })
        );
        return;
      }
      if (errorMatchesMessage(e, "project-not-linked")) {
        dispatch(uiUpdated({ authModalType: "claimApp" }));
        return;
      }
      handleError(e, { t });
    } finally {
      setIsSaving(false);
      setIsOutdatedConfigModalOpen(false);
    }
  }, [
    appBuilder,
    buildChanges,
    buildProject,
    connectionError,
    dispatch,
    isAppBuilderChanged,
    isOutdatedConfigModalOpen,
    isSaving,
    privateKey,
    router,
    saveBuilderConfig,
    saveProject,
    showAppTrackingTransparencyError,
    showGoogleServicesJsonError,
    showInvalidRegex,
    showOneSignalAppIdError,
    showSplashIconsWarning,
    showUniversalLinksWarning,
    showUrlSchemeProtocolError,
    socket,
    t,
    validateAppBuilder,
  ]);

  const handleRestoreBackup = useCallback(async () => {
    try {
      if (isRestoring || !privateKey) return;

      await restoreBackup({
        privateKey,
        versionNumber: backupItem?.version || 0,
      }).unwrap();

      trackEvent("backups_restore");

      window.open(urls.appBuilder(privateKey, SECTION.overview), "_self");
    } catch (e) {
      handleError(e, { t });
    }
  }, [backupItem?.version, isRestoring, privateKey, restoreBackup, t]);

  const handleImportAppConfigModal = useCallback(async () => {
    dispatch(
      uiUpdated({
        authModalType: "importFromApp",
        importFromApp: {
          ...importFromApp,
          destinationAppId: privateKey,
        },
      })
    );
  }, [dispatch, importFromApp, privateKey]);

  const handleTransferAppModal = useCallback(async () => {
    dispatch(organizationUpdated({ transferAppId: privateKey }));
    dispatch(uiUpdated({ authModalType: "transferApp" }));
  }, [dispatch, privateKey]);

  const handleReload = useCallback(async () => {
    if (!privateKey) return;
    try {
      setIsReloading(true);
      await getProject({ privateKey, headers: {} }).unwrap();
      await getProjectStatus({ privateKey, headers: {} }).unwrap();
      const newDate = new Date().toISOString();
      dispatch(uiUpdated({ localLastSavedTime: newDate }));
      toast.dismiss();
    } catch (e) {
    } finally {
      setIsReloading(false);
    }
  }, [dispatch, getProject, getProjectStatus, privateKey]);

  useEffect(() => {
    if (!privateKey || !socket || !!connectionError) return;

    // Emit "join" event with the privateKey
    socket.emit("join", privateKey);

    // Listen for "saveStatus" event
    const saveStatusListener = (data: { lastSavedTime: string }) => {
      dispatch(uiUpdated({ lastSavedTime: data?.lastSavedTime || "" }));
    };
    socket.on("saveStatus", saveStatusListener);

    // Clean up event listener on unmount

    return () => {
      socket.off("saveStatus", saveStatusListener);
    };
  }, [connectionError, dispatch, privateKey, socket]);

  useEffect(() => {
    if (!lastSavedTime) return;

    if (isAppBuilderChanged) {
      if (hasChanges) {
        if (isToastActive) {
          setTimeout(() => {
            toast.update(toastId, {
              render: (
                <ToastMessage
                  hasChange
                  onClick={handleDiscard}
                  onSave={handleSave}
                  isSaving={isSaving}
                  isDiscarding={isDiscarding}
                />
              ),
              position: "bottom-center",
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
            });
          }, 500);
        } else {
          setTimeout(() => {
            toast(
              <ToastMessage
                hasChange
                onClick={handleDiscard}
                onSave={handleSave}
                isSaving={isSaving}
                isDiscarding={isDiscarding}
              />,
              {
                position: "bottom-center",
                toastId,
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                onOpen: () => setIsToastActive(true),
                onClose: () => setIsToastActive(false),
              }
            );
          }, 500);
        }
      } else if (isToastActive && !hasChanges) {
        setTimeout(() => {
          toast.update(toastId, {
            render: (
              <ToastMessage
                onClick={handleReload as () => void}
                isSaving={isReloading}
              />
            ),
            position: "bottom-center",
            autoClose: false,
            closeButton: false,
            closeOnClick: false,
          });
        }, 500);
      } else if (!hasChanges) {
        setTimeout(() => {
          toast(
            <ToastMessage
              onClick={handleReload as () => void}
              isSaving={isReloading}
            />,
            {
              position: "bottom-center",
              toastId,
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
              onOpen: () => setIsToastActive(true),
              onClose: () => setIsToastActive(false),
            }
          );
        }, 500);
      }
    }
  }, [
    handleDiscard,
    handleReload,
    handleSave,
    hasChanges,
    isAppBuilderChanged,
    isDiscarding,
    isReloading,
    isSaving,
    isToastActive,
    lastSavedTime,
  ]);

  if (readonly) {
    return <div className={styles.topBarManage} />;
  }

  if (type === "organization") {
    return (
      <header
        className={classNames(
          styles.topBar,
          type === "organization" && styles.organization
        )}
      >
        {!privateKey && (
          <div className={styles.organizationTitle}>
            {organizationName || t("common.organization")}
          </div>
        )}
        {isLeftNavCollapsed && isRightPanelCollapsed && (
          <Button
            className={classNames(
              styles.togglePhone,
              isPhone && isCartLoaded && styles.cartLoaded
            )}
            onClick={() => dispatch(uiUpdated({ isLeftNavCollapsed: false }))}
            allowOnPreviewMode
          >
            <IconComponent className={styles.togglePhoneComponent} />
            <IconRight className={styles.toggleChevronRight} />
          </Button>
        )}
      </header>
    );
  }

  return (
    <>
      {!isCartLoaded && privateKey && (
        <div className={styles.titlePhone}>
          <img
            alt=""
            className={styles.appIconContainer}
            src={toRelativeUrl(appIcon || defaultAppIcon)!}
            height={42}
            width={42}
          />

          <h1
            className={classNames(
              styles.appName,
              !privateKey && styles.disabled
            )}
          >
            {overviewAppName || t("containers.topBar.defaultAppNameNew")}
          </h1>
        </div>
      )}
      <div className={styles.mainHeader}>
        {isMobile &&
          topBarCartSections.includes(appSection) &&
          isCartLoaded && <PurchaseSummaryBar mode="appBuilder" />}

        <header
          className={classNames(styles.topBar, !privateKey && styles.newApp)}
        >
          {!privateKey && (
            <div className={styles.titlePhone}>
              <img
                alt=""
                className={styles.appIconContainer}
                src={toRelativeUrl(appIcon || defaultAppIcon)!}
                height={42}
                width={42}
              />

              <h1
                className={classNames(
                  styles.appName,
                  !privateKey && styles.disabled
                )}
              >
                {overviewAppName || t("containers.topBar.defaultAppNameNew")}{" "}
              </h1>
            </div>
          )}
          {(!isPhone || !isCartLoaded) && (
            <div className={classNames(styles.title)}>
              <img
                alt=""
                className={styles.appIconContainer}
                src={toRelativeUrl(appIcon || defaultAppIcon)!}
                height={42}
                width={42}
              />

              <h1
                className={classNames(
                  styles.appName,
                  !privateKey && styles.disabled
                )}
              >
                {overviewAppName ||
                  (privateKey
                    ? t("containers.topBar.defaultAppName")
                    : t("containers.topBar.defaultAppNameNew"))}
              </h1>
            </div>
          )}
          {!privateKey && (
            <div className={styles.sectionTitle}>
              {t("sections.newApp.title")}
            </div>
          )}
          {isLeftNavCollapsed && isRightPanelCollapsed && (
            <Button
              className={classNames(
                styles.togglePhone,
                isPhone && isCartLoaded && styles.cartLoaded
              )}
              onClick={() => dispatch(uiUpdated({ isLeftNavCollapsed: false }))}
              allowOnPreviewMode
            >
              <IconComponent className={styles.togglePhoneComponent} />
              <IconRight className={styles.toggleChevronRight} />
            </Button>
          )}
          {privateKey && (
            <div className={styles.items}>
              {backupItem ? (
                <LastModifiedUser
                  className={styles.appSaveInfo}
                  components={{
                    title: <span>{backup.name}</span>,
                    date: <span>{backup.date}</span>,
                  }}
                  i18nKey="containers.topBar.previewBackupBy"
                  i18nKeyWithoutUser="containers.topBar.previewBackup"
                  isDeleted={backup.isModifiedByDeletedUser}
                  isSuperUser={backup.isModifiedBySuperUser}
                  name={backup.modifiedBy}
                />
              ) : (
                lastModifiedDate && (
                  <LastModifiedUser
                    className={styles.appSaveInfo}
                    components={{
                      time: <RelativeDistance dateTime={lastModifiedDate} />,
                    }}
                    i18nKey="containers.topBar.savedBy"
                    i18nKeyWithoutUser="containers.topBar.savedAt"
                    isDeleted={isModifiedByDeletedUser}
                    isSuperUser={isModifiedBySuperUser}
                    name={lastModifiedBy}
                  />
                )
              )}

              {privateKey && (
                <div className={styles.buttonContainer}>
                  {isBackupPreview && access !== "viewer" && (
                    <Button
                      className={styles.button}
                      onClick={handleRestoreBackup}
                      size="lg"
                      type="primary"
                      allowOnPreviewMode
                    >
                      {t("common.restoreBackup")}
                    </Button>
                  )}

                  {!isBackupPreview && hasChanges && (
                    <>
                      <Button
                        className={classNames(
                          styles.button,
                          styles.discardButton
                        )}
                        disabled={isSaving || !hasChanges || isDiscarding}
                        loading={isDiscarding}
                        onClick={handleDiscard}
                        size="lg"
                        type="outlined"
                      >
                        {isMobile
                          ? t("common.discard")
                          : t("containers.topBar.discardChanges")}
                      </Button>

                      <Button
                        className={styles.button}
                        disabled={isSaving || !hasChanges}
                        loading={isSaving}
                        onClick={handleSave}
                        size="lg"
                        type="primary"
                        loaderColor="#ffffff"
                      >
                        {isMobile
                          ? t("common.save")
                          : t("containers.topBar.saveChanges")}
                      </Button>
                    </>
                  )}

                  {!isBackupPreview && !hasChanges && (
                    <Tooltip
                      label={t("containers.topBar.doneEditingTooltip")}
                      placement="bottom"
                    >
                      <Button
                        className={styles.button}
                        onClick={handleDoneEditing}
                        size="lg"
                        type="primary"
                        wide
                        disabled={isDoneEditing}
                        loading={isDoneEditing}
                      >
                        {t("containers.topBar.doneEditing")}
                      </Button>
                    </Tooltip>
                  )}

                  {!isBackupPreview && (
                    <SelectButton
                      isVertical
                      className={styles.menuButton}
                      onChange={(value) => {
                        if (value === "manage-backups") {
                          fetchBackups();
                          trackEvent("backups_view");
                          setIsModalOpen(true);
                        } else if (value === "clone-app") {
                          window.open(urls.clone(publicKey));
                        } else if (value === "share-app") {
                          window.open(urls.share(publicKey));
                        } else if (value === "claim-app") {
                          if (isAuthenticated) {
                            dispatch(
                              uiUpdated({
                                authModalType: "claimApp",
                              })
                            );
                          } else {
                            dispatch(
                              uiUpdated({
                                authModalOpened: "server",
                                progressiveLogin: true,
                                authModalType: "signin/claimApp",
                              })
                            );
                          }
                        } else if (value === "importFromApp") {
                          handleImportAppConfigModal();
                        } else if (value === "transferApp") {
                          handleTransferAppModal();
                        } else if (value === "manageSubscription") {
                          window.open(
                            license.app.subscriptionManagementLink,
                            "_blank"
                          );
                        }
                      }}
                      options={menuOptions}
                      isMenu
                    />
                  )}
                </div>
              )}
            </div>
          )}

          <Button
            className={styles.toggle}
            onClick={() =>
              dispatch(
                uiUpdated({
                  isRightPanelCollapsed: false,
                })
              )
            }
            allowOnPreviewMode
          >
            <IconLeft className={styles.toggleChevron} />

            <IconPreview className={styles.toggleIcon} width={20} />
          </Button>
        </header>

        <ModalManageBackups
          onClose={() => setIsModalOpen(false)}
          onRequestClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        />

        <ModalOutdatedConfig
          onClose={() => {
            setIsOutdatedConfigModalOpen(false);
            setIsSaving(false);
          }}
          onRequestClose={() => {
            setIsOutdatedConfigModalOpen(false);
            setIsSaving(false);
          }}
          onClick={handleSave}
          isOpen={isOutdatedConfigModalOpen}
        />

        <div
          className={classNames(
            styles.topBarGradient,
            isCartLoaded && styles.withCart,
            !privateKey && styles.newApp
          )}
        />
      </div>
    </>
  );
};

export default TopBar;
