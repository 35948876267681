import classNames from "classnames";
import Link from "next/link";
import React, { useCallback, useMemo } from "react";

import Tooltip from "components/common/Tooltip";
import { useAppDispatch, useAppSelector } from "stores";
import { selectOrganization } from "stores/features/organization";
import { selectPrivateKey } from "stores/features/project";
import {
  selectNativeNavigationTab,
  selectServicesTab,
  uiUpdated,
} from "stores/features/ui";
import { ORGSECTION, SECTION } from "utils/constants";
import {
  useAppSection,
  useBackupVersion,
  useMobile,
  useOrgSection,
} from "utils/hooks";
import useIsInOrganization from "utils/hooks/useIsInOrganization";
import urls from "utils/urls";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  icon: React.ReactNode;
  label: string;
  section: SECTION | ORGSECTION;
}

const LeftNavItem: React.FC<Props> = ({
  className,
  disabled,
  icon,
  label,
  section,
}) => {
  const [appSection] = useAppSection();
  const [orgSection] = useOrgSection();
  const isMobile = useMobile();
  const backupVersion = useBackupVersion();
  const dispatch = useAppDispatch();
  const nativeNavigationTab = useAppSelector(selectNativeNavigationTab);
  const servicesTab = useAppSelector(selectServicesTab);
  const privateKey = useAppSelector(selectPrivateKey);
  const { organizationId } = useAppSelector(selectOrganization);
  const isInOrganizationPage = useIsInOrganization();

  const active = isInOrganizationPage
    ? orgSection === section
    : appSection === section;

  const urlSection = backupVersion
    ? `backup/${backupVersion}/${section}`
    : section;

  const url = useMemo(() => {
    if (isInOrganizationPage) {
      return urls.organization(organizationId, section);
    } else if (section === SECTION.nativeNavigation) {
      return urls.appBuilder(privateKey, urlSection, nativeNavigationTab);
    } else if (section === SECTION.services) {
      return urls.appBuilder(privateKey, urlSection, servicesTab);
    } else {
      return urls.appBuilder(privateKey, urlSection);
    }
  }, [
    isInOrganizationPage,
    section,
    organizationId,
    privateKey,
    urlSection,
    nativeNavigationTab,
    servicesTab,
  ]);

  const handleNavItemClick = useCallback(() => {
    if (isMobile) {
      dispatch(uiUpdated({ isLeftNavCollapsed: true }));
    }
  }, [dispatch, isMobile]);

  return (
    <Tooltip containerClassName={styles.tooltipContainer} label={label}>
      <li
        className={classNames(
          styles.navigationItem,
          { [styles.active]: active },
          className
        )}
      >
        {disabled ? (
          <div
            className={classNames(styles.navigationItemButton, styles.disabled)}
          >
            <span
              className={classNames(styles.navigationItemIcon, styles.disabled)}
            >
              {icon}
            </span>

            <span
              className={classNames(
                styles.navigationItemLabel,
                styles.disabled
              )}
            >
              {label}
            </span>
          </div>
        ) : (
          <Link href={url} shallow>
            <a
              className={styles.navigationItemButton}
              onClick={handleNavItemClick}
              onKeyDown={() => null}
              role="button"
              tabIndex={0}
            >
              <span className={styles.navigationItemIcon}>{icon}</span>

              <span className={styles.navigationItemLabel}>{label}</span>
            </a>
          </Link>
        )}
      </li>
    </Tooltip>
  );
};

export default LeftNavItem;
