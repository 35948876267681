import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { useAppSelector } from "stores";
import { selectCart, selectIsCartLoaded } from "stores/features/cart";
import { useMobile } from "utils/hooks";
import urls from "utils/urls";

import { PricingPackage, PricingPackageCallback } from "./constants";

export const usePricingPackage = () => {
  const isMobile = useMobile();
  const router = useRouter();
  const cart = useAppSelector(selectCart);

  const pricingPackage = useMemo(() => {
    const pricingId = router.asPath.split("#").pop() || "";
    if (pricingId === PricingPackage.Cart) {
      if (!cart.assistedFullServiceConfig && !cart.appConfigFullService) {
        return PricingPackage.SelfServe;
      }
      return PricingPackage.FullService;
    }
    if (Object.values(PricingPackage).includes(pricingId as PricingPackage)) {
      return pricingId as PricingPackage;
    }
    if (isMobile) {
      return undefined;
    }
    return PricingPackage.FullService;
  }, [
    router.asPath,
    isMobile,
    cart.assistedFullServiceConfig,
    cart.appConfigFullService,
  ]);

  const setPricingPackage = useCallback(
    (newPricingPackage?: PricingPackage, query?: Record<string, string>) => {
      router.push(urls.pricing(newPricingPackage, query), undefined, {
        shallow: true,
      });
    },
    [router]
  );

  return [pricingPackage, setPricingPackage] as [
    PricingPackage | undefined,
    PricingPackageCallback
  ];
};

interface ScrollOptions {
  cartLoaded?: boolean;
  containerId: string;
  delay?: number;
  offset?: number;
  mode?: "appStudio" | "pricing";
}

export const useScroller = () => {
  const isMobile = useMobile();
  const isCartLoaded = useAppSelector(selectIsCartLoaded);

  const scrollTo = useCallback(
    (options: ScrollOptions) => {
      const { cartLoaded, containerId, delay = 0, offset = 0, mode } = options;
      let extraOffset = isMobile && isCartLoaded ? -80 : 0;
      if (cartLoaded !== undefined) extraOffset = cartLoaded ? -80 : 0;

      setTimeout(() => {
        const container = document.getElementById(
          mode === "appStudio" ? "__main-content" : "__app-content"
        );
        const element = document.getElementById(containerId);
        if (container && element) {
          container.scrollTo({
            top: element.offsetTop + offset + extraOffset,
            behavior: "smooth",
          });
        }
      }, delay);
    },
    [isCartLoaded, isMobile]
  );

  return scrollTo;
};
