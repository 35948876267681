import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "stores";
import {
  backupsUpdated,
  selectIsFetchingBackups,
} from "stores/features/backups";
import { selectPrivateKey } from "stores/features/project";
import api from "utils/api";

export const getWebsiteTitle = (websiteUrl: string, title: string) => {
  if (title || !websiteUrl) {
    return title;
  }

  try {
    // Use website's hostname if there is no title
    const { hostname } = new URL(websiteUrl);
    return hostname.replace(/^www\./, "");
  } catch {
    return title;
  }
};

export const getWebsiteDomain = (websiteUrl: string) => {
  try {
    const { hostname } = new URL(websiteUrl);
    return hostname;
  } catch {
    return "";
  }
};

export const useBackups = () => {
  const dispatch = useAppDispatch();
  const privateKey = useAppSelector(selectPrivateKey);
  const isFetching = useAppSelector(selectIsFetchingBackups);

  const fetchBackups = useCallback(async () => {
    if (isFetching || !privateKey) return;
    try {
      dispatch(backupsUpdated({ isFetching: true }));
      const backups = await api.backups.getBackups(privateKey);
      dispatch(backupsUpdated({ backups }));
    } finally {
      dispatch(backupsUpdated({ isFetching: false }));
    }
  }, [dispatch, isFetching, privateKey]);

  return { isFetching, fetchBackups };
};
