/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback } from "react";

import IconEmptyCart from "assets/images/icons/IconEmptyCart";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import { useCheckout } from "components/containers/RightPanel/Cart/utils";
import PaymentMethods from "components/containers/RightPanel/PaymentMethods";
import { useListPluginsQuery } from "services/api/plugins";
import { useAppDispatch, useAppSelector } from "stores";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectEmail } from "stores/features/appBuilder/overview";
import {
  cartUpdated,
  nativePluginRemovedFromCart,
  pushNotificationsRemovedFromCart,
  selectCart,
  selectCartPrice,
  selectHasPremiumPlugins,
  selectPrices,
} from "stores/features/cart";
import { uiUpdated } from "stores/features/ui";
import { formatPrice } from "utils/formatting";
import { useDesktop, useIsLicensed, usePhone } from "utils/hooks";

import IconArrowLeft from "../icons/arrowLeft.svg";
import IconCart from "../icons/cart.svg";
import { useScroller } from "../utils";
import CostSummaryItem from "./CostSummaryItem";
import styles from "./styles.module.scss";

interface Props {
  className?: string;
  contained?: boolean;
  placeholder?: boolean;
  placeholderClassName?: string;
  placeholderLabel?: string;
  type: "pricing" | "appBuilder" | "cart";
}

const CostSummary: React.FC<Props> = ({
  className,
  contained,
  placeholder,
  placeholderClassName,
  placeholderLabel,
  type = "pricing",
}) => {
  const { isCheckingOut, onCheckout } = useCheckout({ mode: type });
  const { data: nativePluginsList = [] } = useListPluginsQuery();
  const { lang, t } = useTranslation();
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const license = useAppSelector(selectLicense);
  const email = useAppSelector(selectEmail);
  const isLicensed = useIsLicensed();
  const scrollTo = useScroller();
  const isPhone = usePhone();
  const isDesktop = useDesktop();

  const cartPrice = useAppSelector((state) =>
    selectCartPrice(state, nativePluginsList)
  );

  const hasPremiumPlugins = useAppSelector((state) =>
    selectHasPremiumPlugins(state, nativePluginsList)
  );

  const {
    appConfigFullService,
    samUpdates,
    assistedFullServiceConfig,
    basicLicense,
    professionalPublishInitial,
    professionalPublishUpdate,
    professionalSupport,
    developerOnboarding,
    virtualOnsite,
  } = useAppSelector((state) => selectPrices(state, nativePluginsList));

  let licensePrice = basicLicense;

  if (cart.assistedFullServiceConfig || cart.appConfigFullService) {
    licensePrice =
      basicLicense +
      assistedFullServiceConfig +
      appConfigFullService +
      professionalPublishInitial;
  }

  const supportPrice = samUpdates + professionalSupport;

  const totalPrice = cartPrice;

  const onClearCart = useCallback(() => {
    dispatch(
      cartUpdated({
        cart: {
          basicLicense: false,
          assistedFullServiceConfig: false,
          professionalPublishInitial: false,
          professionalPublishUpdate: false,
          professionalSupport: false,
          appConfigFullService: false,
          samUpdates: false,
          developerOnboarding: false,
          virtualOnsite: false,
          pushNotifications: [],
          nativePlugins: [],
        },
      })
    );

    if (!isDesktop) {
      dispatch(uiUpdated({ isRightPanelCollapsed: true }));
    }

    if (type === "pricing") {
      scrollTo({
        containerId: "__pricing-header",
        delay: 100,
        offset: 0,
        cartLoaded: false,
      });
    }
  }, [dispatch, isDesktop, scrollTo, type]);

  if (placeholder) {
    return (
      <div
        id="__cost-summary"
        className={classNames(
          styles.costSummaryContainer,
          contained && styles.contained,
          placeholderClassName
        )}
      >
        <div
          className={classNames(
            styles.costSummaryContent,
            contained && styles.contained
          )}
        >
          <div className={styles.placeholder}>
            <div className={styles.border}>
              <div className={styles.center}>
                <div className={styles.icon}>
                  <IconArrowLeft />
                </div>
                <div className={styles.label}>{placeholderLabel}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="__cost-summary"
      className={classNames(
        styles.costSummaryContainer,
        contained && styles.contained,
        className
      )}
    >
      <div
        className={classNames(
          styles.costSummaryContent,
          contained && styles.contained
        )}
      >
        <div className={classNames(styles.costSummaryHeader)}>
          {t("containers.pricing.costSummary.title")}
        </div>
        {type === "pricing" && (
          <CostSummaryItem
            label={
              // eslint-disable-next-line no-nested-ternary
              !cart.assistedFullServiceConfig && !cart.appConfigFullService
                ? t("containers.pricing.costSummary.license")
                : cart.appConfigFullService
                ? t("containers.pricing.costSummary.fullServiceAppDevelopment")
                : t("containers.pricing.costSummary.assistedAppDevelopment")
            }
            price={licensePrice}
            onRemove={() => {
              dispatch(
                cartUpdated({
                  cart: {
                    appConfigFullService: false,
                    samUpdates: false,
                    assistedFullServiceConfig: false,
                    basicLicense: false,
                    nativePlugins: [],
                    professionalPublishInitial: false,
                    professionalPublishUpdate: false,
                    professionalSupport: true,
                    pushNotifications: [],
                    samEnterprise: false,
                    selfSupport: false,
                  },
                })
              );
            }}
            removable
          />
        )}
        {type !== "pricing" && (
          <>
            {cart.basicLicense && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.license")}
                price={basicLicense}
              />
            )}
            {!isLicensed && !cart.professionalPublishInitial && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.addPublishInitial")}
                onAdd={() => {
                  dispatch(
                    cartUpdated({
                      cart: { professionalPublishInitial: true },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
              />
            )}

            {cart.professionalPublishInitial && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.publishInitial")}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { professionalPublishInitial: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={professionalPublishInitial}
                removable
              />
            )}

            {cart.professionalPublishUpdate && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.publishUpdate")}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { professionalPublishUpdate: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={professionalPublishUpdate}
                removable
              />
            )}

            {cart.assistedFullServiceConfig && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.assisted")}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { assistedFullServiceConfig: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={assistedFullServiceConfig}
                removable
              />
            )}

            {cart.appConfigFullService && (
              <CostSummaryItem
                label={t(
                  "containers.pricing.costSummary.fullServiceAppDevelopment"
                )}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { appConfigFullService: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={appConfigFullService}
                removable
              />
            )}

            {cart.developerOnboarding && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.developerOnboarding")}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { developerOnboarding: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={developerOnboarding}
                removable
              />
            )}

            {cart.virtualOnsite && (
              <CostSummaryItem
                label={t("containers.pricing.costSummary.virtualOnsite")}
                onRemove={() => {
                  dispatch(
                    cartUpdated({
                      cart: { virtualOnsite: false },
                      emailAnalytics: email,
                      licensed: isLicensed,
                    })
                  );
                }}
                price={virtualOnsite}
                removable
              />
            )}
          </>
        )}
        {cart.pushNotifications.map((plugin) => (
          <CostSummaryItem
            key={plugin.gnid}
            label={`${plugin.name} (${t("containers.pricing.oneTime")})`}
            onRemove={() => {
              dispatch(pushNotificationsRemovedFromCart(plugin.slug));
            }}
            price={plugin.price}
            removable
          />
        ))}
        {cart.nativePlugins.map((plugin) => (
          <CostSummaryItem
            key={plugin.gnid}
            label={`${plugin.name} (${t("containers.pricing.oneTime")})`}
            onRemove={() => {
              dispatch(nativePluginRemovedFromCart(plugin.slug));
            }}
            price={plugin.price}
            removable
          />
        ))}

        {cart.samUpdates && (
          <CostSummaryItem
            label={t("containers.pricing.costSummary.samUpdates")}
            onRemove={() => {
              dispatch(
                cartUpdated({
                  cart: { samUpdates: false },
                  emailAnalytics: email,
                  licensed: isLicensed,
                })
              );
            }}
            price={supportPrice}
            removable
          />
        )}

        {!cart.professionalSupport &&
          hasPremiumPlugins &&
          license.app.support === "appUpdates" && (
            <CostSummaryItem
              label={t("containers.pricing.costSummary.samUpdatesInc")}
              price={supportPrice}
            />
          )}
        {cart.professionalSupport && (
          <CostSummaryItem
            label={
              license.app.support === "appUpdatesPremium"
                ? t("containers.pricing.costSummary.appUpdatesInc")
                : license.app.support === "appUpdates"
                ? hasPremiumPlugins
                  ? t(
                      "containers.pricing.costSummary.appUpdatesHasPremiumPlugin"
                    )
                  : t("containers.pricing.costSummary.appUpdatesInc")
                : t("containers.pricing.costSummary.professionalSupport")
            }
            onRemove={() => {
              dispatch(
                cartUpdated({
                  cart: { professionalSupport: false },
                  emailAnalytics: email,
                  licensed: isLicensed,
                })
              );
            }}
            price={professionalSupport}
            removable
          />
        )}

        {!cart.professionalSupport &&
          supportPrice > 0 &&
          license.app.support === "basic" && (
            <CostSummaryItem
              label={t("containers.pricing.costSummary.professionalSupportInc")}
              price={supportPrice}
            />
          )}
        <div className={styles.costSummaryTotalContainer}>
          <div className={styles.costSummaryTotal}>
            {t("containers.pricing.costSummary.total")}
          </div>

          <div className={styles.costSummaryTotalPrice}>
            {formatPrice(lang, totalPrice)}
          </div>
        </div>
        <div className={styles.costSummaryButtonContainer}>
          <Button
            className={classNames(
              styles.costSummaryStepButton,
              type === "appBuilder" && styles.iconOnly
            )}
            disabled={isCheckingOut}
            onClick={onClearCart}
            type="light"
          >
            <IconEmptyCart width={24} height={24} />

            {type === "pricing" && !isPhone && (
              <div className={styles.costSummaryCheckout}>
                {t("containers.pricing.costSummary.clearCart")}
              </div>
            )}
          </Button>
          <Button
            className={classNames(
              styles.costSummaryStepButton,
              styles.checkoutButton
            )}
            disabled={isCheckingOut}
            onClick={onCheckout}
            type="secondary"
          >
            <>
              <IconCart />
              <div className={styles.costSummaryCheckout}>
                {t("containers.pricing.costSummary.checkout")}
              </div>
            </>

            {isCheckingOut && (
              <Loader className={styles.loader} color="black" size="sm" />
            )}
          </Button>
        </div>
        <PaymentMethods />
      </div>
    </div>
  );
};

export default CostSummary;
