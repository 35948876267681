/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useMemo } from "react";

import IconChevronLeft from "assets/images/icons/chevronLeft.svg";
import IconChevronRight from "assets/images/icons/chevronRight.svg";
import IconAccount from "assets/images/icons/IconAccount";
import IconLicense from "assets/images/icons/IconLicense";
import IconServices from "assets/images/icons/IconServices";
import Tooltip from "components/common/Tooltip";
import { useAppDispatch, useAppSelector } from "stores";
import { selectOverview } from "stores/features/appBuilder/overview";
import { selectIsBackupPreview } from "stores/features/backups";
import { selectOrganizationName } from "stores/features/organization";
import { selectIsLeftNavCollapsed, uiUpdated } from "stores/features/ui";
import { selectUserId } from "stores/features/user";
import { ORGSECTION, SECTION } from "utils/constants";
import {
  useDesktop,
  useMobile,
  usePerfectScrollbar,
  usePhone,
} from "utils/hooks";
import useIsInOrganization from "utils/hooks/useIsInOrganization";
import urls from "utils/urls";

import IconAccess from "./icons/access.svg";
import IconApps from "./icons/apps.svg";
import IconBranding from "./icons/branding.svg";
import IconBuild from "./icons/build.svg";
import IconInterface from "./icons/interface.svg";
import IconLinkHandling from "./icons/linkHandling.svg";
import IconMembers from "./icons/members.svg";
import IconNativeNavigation from "./icons/nativeNavigation.svg";
import IconNativePlugins from "./icons/nativePlugins.svg";
import IconOverview from "./icons/overview.svg";
import IconPermissions from "./icons/permissions.svg";
import IconPushNotifications from "./icons/pushNotifications.svg";
import IconSettings from "./icons/settings.svg";
import IconSupport from "./icons/support.svg";
import IconWebsiteOverrides from "./icons/websiteOverrides.svg";
import LeftNavItem from "./LeftNavItem";
import styles from "./styles.module.scss";

type SectionIcons = {
  [key in SECTION]?: React.ReactNode;
};

type OrgSectionIcons = {
  [key in ORGSECTION]?: React.ReactNode;
};

const sectionIcons: SectionIcons = {
  overview: <IconOverview />,
  branding: <IconBranding />,
  linkHandling: <IconLinkHandling />,
  interface: <IconInterface />,
  websiteOverrides: <IconWebsiteOverrides />,
  permissions: <IconPermissions />,
  nativeNavigation: <IconNativeNavigation />,
  pushNotifications: <IconPushNotifications />,
  nativePlugins: <IconNativePlugins />,
  build: <IconBuild />,
  license: <IconLicense />,
  annualPlan: <IconSupport />,
  services: <IconServices />,
  access: <IconAccess />,
};

const orgSectionIcons: OrgSectionIcons = {
  apps: <IconApps />,
  members: <IconMembers />,
  settings: <IconSettings />,
};

interface Props {
  disabled?: boolean;
}

const LeftNav: React.FC<Props> = ({ disabled }) => {
  const dispatch = useAppDispatch();
  const isLeftNavCollapsed = useAppSelector(selectIsLeftNavCollapsed);
  const overview = useAppSelector(selectOverview);
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const isDesktop = useDesktop();
  const isPhone = usePhone();
  const isMobile = useMobile();
  const isInOrganizationPage = useIsInOrganization();
  const userId = useAppSelector(selectUserId);
  const { t } = useTranslation();

  const organizationName = useAppSelector(selectOrganizationName);

  const containerRef = usePerfectScrollbar();

  useEffect(() => {
    if (isDesktop) {
      dispatch(uiUpdated({ isLeftNavCollapsed: false }));
    } else if (isMobile || isPhone) {
      dispatch(uiUpdated({ isLeftNavCollapsed: true }));
    }
  }, [dispatch, isDesktop, isMobile, isPhone]);

  const leftNavItems = useMemo(() => {
    const { access, ...otherIcons } = sectionIcons;
    const icons = isInOrganizationPage
      ? orgSectionIcons
      : overview.organizationName && !isBackupPreview
      ? sectionIcons
      : otherIcons;

    const newNavItems = Object.entries(icons).map(
      ([section, icon], index, allItems) => (
        <React.Fragment key={section}>
          <LeftNavItem
            disabled={disabled}
            icon={icon}
            label={t(`sections.${section}.titleSidebar`)}
            section={section as SECTION}
          />
          {section === "build" && allItems[index + 1]?.[0] === "license" && (
            <div className={styles.menuDivider} key={`${section}-divider`} />
          )}
        </React.Fragment>
      )
    );

    return newNavItems;
  }, [
    disabled,
    isBackupPreview,
    isInOrganizationPage,
    overview.organizationName,
    t,
  ]);

  return (
    <>
      {!isLeftNavCollapsed && (
        <div
          className={styles.overlay}
          onClick={() => dispatch(uiUpdated({ isLeftNavCollapsed: true }))}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
        >
          {}
        </div>
      )}

      <nav
        className={classNames(styles.container, {
          [styles.collapsed]: isLeftNavCollapsed,
        })}
        ref={containerRef}
      >
        <div className={styles.navigationContainer}>
          <header className={styles.header}>
            {isInOrganizationPage ? (
              <h2 className={styles.orgName}>
                {organizationName || t("common.organization")}
              </h2>
            ) : (
              <h2 className={styles.headerTitle}>
                {t("containers.leftNav.title")}
              </h2>
            )}
            <Tooltip
              label={
                isLeftNavCollapsed ? t("common.expand") : t("common.collapse")
              }
            >
              <button
                className={styles.toggle}
                onClick={() =>
                  dispatch(
                    uiUpdated({ isLeftNavCollapsed: !isLeftNavCollapsed })
                  )
                }
                type="button"
              >
                {isLeftNavCollapsed ? (
                  <IconChevronRight />
                ) : (
                  <IconChevronLeft
                    className={classNames(
                      isLeftNavCollapsed && styles.toggleIconCollapsed
                    )}
                  />
                )}
              </button>
            </Tooltip>
          </header>
          <ul
            className={classNames(
              styles.navigation,
              isInOrganizationPage && styles.main
            )}
          >
            {leftNavItems}
          </ul>
        </div>
        {isInOrganizationPage && userId && (
          <div className={styles.userTab}>
            <div className={styles.menuDivider} />
            <ul className={classNames(styles.navigation, styles.account)}>
              <li className={classNames(styles.navigationItem)}>
                <Tooltip
                  containerClassName={styles.tooltipContainer}
                  label={t("sections.members.userDetails.myAccount.title")}
                >
                  <a
                    className={styles.navigationItemButton}
                    href={urls.accountUrl(userId)}
                    onKeyDown={() => null}
                    role="button"
                    target="_blank"
                    tabIndex={0}
                    rel="noreferrer"
                  >
                    <span className={styles.navigationItemIcon}>
                      <IconAccount />
                    </span>

                    <span className={styles.navigationItemLabel}>
                      {t("sections.members.userDetails.myAccount.title")}
                    </span>
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};

export default LeftNav;
