import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  centerFill?: string;
  containerFill?: string;
};

const IconAccount: React.FC<Props> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 3C9.2417 3 9.9667 3.21993 10.5834 3.63199C11.2001 4.04404 11.6807 4.62971 11.9645 5.31494C12.2484 6.00016 12.3226 6.75416 12.1779 7.48159C12.0333 8.20902 11.6761 8.8772 11.1517 9.40165C10.6272 9.9261 9.959 10.2833 9.2316 10.4279C8.5042 10.5726 7.7502 10.4984 7.0649 10.2145C6.37971 9.93072 5.79404 9.45007 5.38199 8.83339C4.96993 8.2167 4.75 7.49168 4.75 6.75C4.75 5.75544 5.14509 4.80161 5.84835 4.09835C6.5516 3.39509 7.5054 3 8.5 3ZM8.5 1.5C7.4616 1.5 6.44661 1.80791 5.58326 2.38478C4.7199 2.96166 4.04699 3.7816 3.64963 4.74091C3.25227 5.70022 3.14831 6.75582 3.35088 7.77422C3.55345 8.79262 4.05346 9.72808 4.78769 10.4623C5.52192 11.1965 6.45738 11.6966 7.4758 11.8991C8.4942 12.1017 9.5498 11.9977 10.5091 11.6004C11.4684 11.203 12.2883 10.5301 12.8652 9.66674C13.4421 8.80339 13.75 7.78835 13.75 6.75C13.75 5.35761 13.1969 4.02226 12.2123 3.03769C11.2277 2.05312 9.8924 1.5 8.5 1.5ZM10.75 13.5V15H6.25C5.25544 15 4.30161 15.3951 3.59835 16.0983C2.89509 16.8016 2.5 17.7554 2.5 18.75V22.5H1V18.75C1 17.3576 1.55312 16.0223 2.53769 15.0377C3.52226 14.0531 4.85761 13.5 6.25 13.5H10.75Z"
      fill="currentColor"
    />
    <path
      d="M23 17.2857V15.7143H21.3491C21.2478 15.2225 21.0526 14.7548 20.7741 14.337L21.9448 13.1663L20.8338 12.0553L19.6631 13.226C19.2452 12.9475 18.7776 12.7522 18.2857 12.6509V11H16.7143V12.6509C16.2225 12.7522 15.7548 12.9474 15.337 13.2259L14.1663 12.0552L13.0553 13.1662L14.226 14.3369C13.9475 14.7548 13.7522 15.2224 13.6509 15.7143H12V17.2857H13.6509C13.7522 17.7775 13.9474 18.2452 14.2259 18.663L13.0552 19.8337L14.1662 20.9447L15.3369 19.774C15.7548 20.0525 16.2224 20.2478 16.7143 20.3491V22H18.2857V20.3491C18.7775 20.2478 19.2452 20.0526 19.663 19.7741L20.8337 20.9448L21.9447 19.8338L20.774 18.6631C21.0525 18.2452 21.2478 17.7776 21.3491 17.2857H23ZM17.5 18.8571C17.0338 18.8571 16.5781 18.7189 16.1904 18.4599C15.8028 18.2009 15.5007 17.8328 15.3223 17.402C15.1439 16.9713 15.0972 16.4974 15.1881 16.0401C15.2791 15.5829 15.5036 15.1629 15.8332 14.8332C16.1629 14.5036 16.5829 14.2791 17.0401 14.1881C17.4974 14.0972 17.9713 14.1439 18.402 14.3223C18.8328 14.5007 19.2009 14.8028 19.4599 15.1904C19.7189 15.5781 19.8571 16.0338 19.8571 16.5C19.8565 17.1249 19.6079 17.7241 19.166 18.166C18.7241 18.6079 18.1249 18.8565 17.5 18.8571Z"
      fill="currentColor"
    />
  </svg>
);

export default IconAccount;
